<template>
  <!--DESKTOP DESIGN-->
  <div
    v-if="
      $vuetify.breakpoint.md || $vuetify.breakpoint.lg || $vuetify.breakpoint.xl
    "
  >
    <v-container>
      <!--Show introduction-->
      <v-card class="d-flex" v-if="introduction.active">
        <v-card-text>
          <v-row>
            <v-col xs="12" sm="12" md="3" lg="3" xl="3">
              <v-card outlined class="flex-card" height="100%">
                <v-card-text>
                  <v-img
                    lazy-src="@/assets/images/logo.png"
                    max-height="250"
                    max-width="250"
                    src="@/assets/images/logo.png"
                  ></v-img>
                  <p
                    class="pt-5 pb-5 font-weight-medium"
                    v-html="introduction.leftSection.text1"
                  ></p>
                  <p
                    class="pt-5 pb-5 font-weight-medium"
                    v-html="introduction.leftSection.text2"
                  ></p>
                </v-card-text>
                <v-card-actions class="justify-center">
                  <v-btn
                    class="white--text caption font-weight-bold"
                    large
                    elevation="2"
                    rounded
                    color="orange"
                    @click="start()"
                  >
                    {{ introduction.action }}
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col xs="12" sm="12" md="9" lg="9" xl="9">
              <v-card>
                <v-card-text transition="slide-x-transition">
                  <div
                    class="wistia_responsive_padding"
                    style="padding:56.25% 0 0 0;position:relative;"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="height:100%;left:0;position:absolute;top:0;width:100%;"
                    >
                      <iframe
                        :src="introduction.mainSection.id"
                        :title="introduction.mainSection.title"
                        allow="autoplay; fullscreen"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        class="wistia_embed"
                        name="wistia_embed"
                        msallowfullscreen
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!--End introduction-->
      <!--Show steps-->
      <v-card v-else>
        <div v-if="step.id === currentStep" v-for="(step, n) in steps">
          <div
            v-if="step.hasSubSteps && subStep.id === currentSubStep"
            v-for="(subStep, s) in step.subSteps"
          >
            <v-card-text>
              <v-container fluid>
                <v-row>
                  <v-col
                    v-if="subStep.hasLeftSection"
                    sm="3"
                    md="3"
                    lg="3"
                    xl="3"
                    align-self="center"
                    class="d-flex justify-center"
                  >
                    <v-card class="flex-card" height="100%">
                      <v-card-title v-if="subStep.leftSection.hasTitle">
                        {{ subStep.leftSection.title }}
                      </v-card-title>
                      <v-card-subtitle v-if="subStep.leftSection.hasSubTitle">
                        {{ subStep.leftSection.subtitle }}
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-container fluid>
                          <v-row v-for="content in subStep.leftSection.content">
                            <v-col
                              v-if="content.type === 'image'"
                              class="d-flex justify-center"
                            >
                              <v-img
                                :lazy-src="content.url"
                                max-height="200"
                                max-width="200"
                                contain
                                :src="content.url"
                              ></v-img>
                            </v-col>
                            <v-col
                              v-else="content.type === 'text'"
                              class="d-flex justify-center"
                            >
                              <p
                                class="pt-5 pb-5 font-weight-medium"
                                v-html="content.text"
                              ></p>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <v-divider></v-divider>
                      <v-card-actions
                        v-for="action in subStep.leftSection.actions"
                        class="d-flex justify-center"
                      >
                        <v-btn
                          class="white--text caption font-weight-bold"
                          large
                          elevation="2"
                          rounded
                          color="orange"
                          @click="actionsLeftSection(action.action)"
                        >
                          {{ action.label }}
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                  <v-col
                    :sm="subStep.hasLeftSection ? 9 : 12"
                    :md="subStep.hasLeftSection ? 9 : 12"
                    :lg="subStep.hasLeftSection ? 9 : 12"
                    :xl="subStep.hasLeftSection ? 9 : 12"
                  >
                    <v-card>
                      <v-card-text>
                        <v-container fluid>
                          <v-row>
                            <v-col
                              :sm="!subStep.hasLeftSection ? 8 : 8"
                              :md="!subStep.hasLeftSection ? 8 : 8"
                              :lg="!subStep.hasLeftSection ? 8 : 8"
                              :xl="!subStep.hasLeftSection ? 8 : 8"
                            >
                              <v-card class="flex-card" height="100%">
                                <v-card-title
                                  v-if="subStep.mainSection.hasTitle"
                                >
                                  {{ subStep.mainSection.title }}
                                </v-card-title>
                                <v-card-subtitle
                                  v-if="subStep.mainSection.hasSubTitle"
                                >
                                  {{ subStep.mainSection.subtitle }}
                                </v-card-subtitle>
                                <v-divider></v-divider>
                                <v-card-text
                                  v-if="subStep.mainSection.hasContent"
                                >
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-if="
                                      subStep.mainSection.content.type ===
                                        'selector'
                                    "
                                  >
                                    <v-card-text>
                                      <v-row>
                                        <v-col
                                          cols="4"
                                          v-for="avatar in subStep.mainSection
                                            .content.avatars"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            max-height="150"
                                            max-width="100"
                                            color="transparent"
                                            @click="
                                              selectAvatar(avatar.id, false)
                                            "
                                          >
                                            <v-img
                                              :lazy-src="avatar.url"
                                              max-height="150"
                                              max-width="100"
                                              contain
                                              :src="avatar.url"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-if="
                                      subStep.mainSection.content.type ===
                                        'selectorComorbidity'
                                    "
                                  >
                                    <v-card-text>
                                      <v-row
                                        v-if="randomAvatarSelected === false"
                                      >
                                        <v-col
                                          cols="4"
                                          v-for="avatar in subStep.mainSection
                                            .content.avatars"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            outlined
                                            tile
                                            color="transparent"
                                            @click="
                                              selectComorbidity(
                                                avatar.id,
                                                avatar.name,
                                                avatar.video1,
                                                avatar.video2
                                              )
                                            "
                                          >
                                            <v-img
                                              style="border:2px solid red; border-radius: 25px;"
                                              :lazy-src="avatar.url"
                                              max-height="200"
                                              max-width="250"
                                              contain
                                              :src="avatar.url"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row v-else>
                                        <v-col
                                          cols="12"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            style="border:2px solid red; border-radius: 25px;"
                                          >
                                            <v-img
                                              :lazy-src="imageComorbidiy"
                                              max-height="200"
                                              max-width="200"
                                              contain
                                              :src="imageComorbidiy"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row
                                        v-if="randomAvatarSelected === false"
                                      >
                                        <v-col
                                          class="mt-20 d-flex justify-center"
                                          v-if="showComorbidity"
                                        >
                                          <h6>
                                            You have selected the
                                            {{
                                              comorbiditySelected
                                            }}
                                            comorbidity
                                          </h6>
                                        </v-col>
                                      </v-row>
                                      <v-row v-else>
                                        <v-col
                                          class="mt-20 d-flex justify-center"
                                        >
                                          <h6>
                                            The randomize function has selected
                                            {{
                                              comorbiditySelected
                                            }}
                                            comorbidity
                                          </h6>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-if="
                                      subStep.mainSection.content.type ===
                                        'selectorMB'
                                    "
                                  >
                                    <v-card-text>
                                      <v-row
                                        v-if="randomAvatarSelected === false"
                                      >
                                        <v-col
                                          cols="4"
                                          v-for="avatar in subStep.mainSection
                                            .content.avatars"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            outlined
                                            tile
                                            color="transparent"
                                            @click="
                                              selectMB(
                                                avatar.id,
                                                avatar.name,
                                                avatar.video
                                              )
                                            "
                                          >
                                            <v-img
                                              style="border:2px solid green; border-radius: 25px;"
                                              :lazy-src="avatar.url"
                                              max-height="200"
                                              max-width="200"
                                              contain
                                              :src="avatar.url"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row v-else>
                                        <v-col
                                          cols="12"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            style="border:2px solid red; border-radius: 25px;"
                                          >
                                            <v-img
                                              :lazy-src="imageMB"
                                              max-height="200"
                                              max-width="200"
                                              contain
                                              :src="imageMB"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          class="mt-20 d-flex justify-center"
                                          v-if="showMB"
                                        >
                                          <h6>
                                            You have selected the
                                            {{ MBSelected }} modifiable
                                            behaviour
                                          </h6>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-if="
                                      subStep.mainSection.content.type ===
                                        'selectorSDoH'
                                    "
                                  >
                                    <v-card-text>
                                      <v-row>
                                        <v-col
                                          :cols="key >= 3 ? '6' : '4'"
                                          v-for="(avatar, key) in subStep
                                            .mainSection.content.avatars"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            outlined
                                            tile
                                            color="transparent"
                                            @click="
                                              selectSDoH(
                                                avatar.id,
                                                avatar.name,
                                                avatar.video
                                              )
                                            "
                                          >
                                            <v-img
                                              :style="avatar.color"
                                              :lazy-src="avatar.url"
                                              max-height="150"
                                              max-width="150"
                                              contain
                                              :src="avatar.url"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          class="mt-20 d-flex justify-center"
                                          v-if="showSDoH"
                                        >
                                          <h6>
                                            You have selected the
                                            {{ SDoHSelected }} social
                                            determinant of health
                                          </h6>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-if="
                                      subStep.mainSection.content.type ===
                                        'selectorGLP1'
                                    "
                                  >
                                    <v-card-text>
                                      <v-row>
                                        <v-col
                                          :cols="key >= 4 ? '4' : '3'"
                                          v-for="(avatar, key) in subStep
                                            .mainSection.content.avatars"
                                          class="d-flex justify-center"
                                        >
                                          <v-card
                                            @click="
                                              selectGLP1(
                                                avatar.id,
                                                avatar.name,
                                                avatar.video
                                              )
                                            "
                                          >
                                            <v-img
                                              :lazy-src="avatar.url"
                                              max-height="150"
                                              max-width="150"
                                              contain
                                              :src="avatar.url"
                                            ></v-img>
                                          </v-card>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          class="mt-20 d-flex justify-center"
                                          v-if="showGLP1"
                                        >
                                          <h6>
                                            You have selected the
                                            {{ GLP1Selected }} GLP-1
                                          </h6>
                                        </v-col>
                                      </v-row>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'video'
                                    "
                                  >
                                    <div
                                      class="wistia_responsive_padding"
                                      style="padding:56.25% 0 0 0;position:relative;"
                                    >
                                      <div
                                        class="wistia_responsive_wrapper"
                                        style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                      >
                                        <iframe
                                          :src="
                                            subStep.mainSection.content
                                              .conditional === false
                                              ? subStep.mainSection.content.url
                                              : selectorSpecificVideo
                                          "
                                          :title="
                                            subStep.mainSection.content.title
                                          "
                                          allow="autoplay; fullscreen"
                                          allowtransparency="true"
                                          frameborder="0"
                                          scrolling="no"
                                          class="wistia_embed"
                                          name="wistia_embed"
                                          msallowfullscreen
                                          width="100%"
                                          height="100%"
                                        ></iframe>
                                      </div>
                                    </div>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'videoGLP1'
                                    "
                                  >
                                    <v-row no-gutters dense>
                                      <v-col>
                                        <div
                                          class="wistia_responsive_padding"
                                          style="padding:56.25% 0 0 0;position:relative;"
                                        >
                                          <div
                                            class="wistia_responsive_wrapper"
                                            style="height:100%;left:0;position:absolute;top:0;width:100%;"
                                          >
                                            <iframe
                                              :src="
                                                subStep.mainSection.content
                                                  .conditional === false
                                                  ? subStep.mainSection.content
                                                      .url
                                                  : selectorSpecificVideo
                                              "
                                              :title="
                                                subStep.mainSection.content
                                                  .title
                                              "
                                              allow="autoplay; fullscreen"
                                              allowtransparency="true"
                                              frameborder="0"
                                              scrolling="no"
                                              class="wistia_embed"
                                              name="wistia_embed"
                                              msallowfullscreen
                                              width="100%"
                                              height="100%"
                                            ></iframe>
                                          </div>
                                        </div>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        ><h6>
                                          Do you wish to continue with this
                                          agent or choose a different one?.
                                          Select your preferred agent before
                                          continuing.
                                        </h6></v-col
                                      >
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        cols="6"
                                        class="d-flex justify-center"
                                      >
                                        <v-btn
                                          class="white--text caption font-weight-bold"
                                          large
                                          elevation="2"
                                          rounded
                                          color="orange"
                                          @click="btnGLP1(true)"
                                          >Yes
                                        </v-btn>
                                      </v-col>
                                      <v-col
                                        cols="6"
                                        class="d-flex justify-center"
                                      >
                                        <v-btn
                                          class="white--text caption font-weight-bold"
                                          large
                                          elevation="2"
                                          rounded
                                          color="orange"
                                          @click="btnGLP1(false)"
                                          >No
                                        </v-btn>
                                      </v-col>
                                    </v-row>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'scaleA1C'
                                    "
                                  >
                                    <v-card-text>
                                      <div style="height: 400px">
                                        <br />
                                        <h6
                                          v-if="randomAvatarSelected === false"
                                          class="pt-5 pb-5 text-center"
                                          v-html="
                                            subStep.mainSection.content.mainText
                                          "
                                        ></h6>
                                        <h6
                                          v-else
                                          class="pt-5 pb-5 text-center"
                                        >
                                          The randomize has selected the value
                                          of {{ a1cLevel }}
                                        </h6>
                                        <br />
                                        <p class="mr-5 ml-5">
                                          <v-slider
                                            @change="changeSliderA1C"
                                            v-model="a1cLevel"
                                            :rules="
                                              subStep.mainSection.content.rules
                                            "
                                            :min="
                                              subStep.mainSection.content.min
                                            "
                                            :max="
                                              subStep.mainSection.content.max
                                            "
                                            :step="
                                              subStep.mainSection.content.step
                                            "
                                            hint=""
                                            label=""
                                            persistent-hint
                                            thumb-label="always"
                                            :color="colorSlider"
                                            ticks
                                            :disabled="
                                              randomAvatarSelected
                                                ? true
                                                : false
                                            "
                                          ></v-slider>
                                          <v-range-slider
                                            id="informativeSlider"
                                            :tick-labels="
                                              subStep.mainSection.content
                                                .ticksLabels
                                            "
                                            v-model="
                                              subStep.mainSection.content.range
                                            "
                                            min="4"
                                            max="12"
                                            step="2"
                                            tick-size="5"
                                            ticks
                                            :disabled="
                                              randomAvatarSelected
                                                ? true
                                                : false
                                            "
                                          >
                                          </v-range-slider>
                                        </p>
                                        <v-row>
                                          <v-col cols="4">
                                            <p class="pt-5 pb-5 text-center">
                                              <v-img
                                                lazy-src="@/assets/images/A1C.jpg"
                                                max-height="100"
                                                max-width="250"
                                                src="@/assets/images/A1C.jpg"
                                              ></v-img>
                                            </p>
                                          </v-col>
                                          <v-col cols="8">
                                            <h6
                                              v-if="
                                                randomAvatarSelected === false
                                              "
                                              class="pt-5 pb-5 text-left"
                                              v-html=""
                                            >
                                              {{
                                                subStep.mainSection.content
                                                  .subText
                                              }}
                                              {{ a1cLevel }}
                                            </h6>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'scaleBMI'
                                    "
                                  >
                                    <v-card-text>
                                      <div style="height: 400px">
                                        <br />
                                        <h6
                                          v-if="randomAvatarSelected === false"
                                          class="pt-5 pb-5 text-center"
                                          v-html="
                                            subStep.mainSection.content.mainText
                                          "
                                        ></h6>
                                        <h6
                                          v-else
                                          class="pt-5 pb-5 text-center"
                                        >
                                          The randomize has selected the value
                                          of {{ bmiLevel }}
                                        </h6>
                                        <br />
                                        <br />
                                        <p class="mr-5 ml-5">
                                          <v-slider
                                            @change="changeSliderBMI"
                                            v-model="bmiLevel"
                                            :rules="
                                              subStep.mainSection.content.rules
                                            "
                                            :min="
                                              subStep.mainSection.content.min
                                            "
                                            :max="
                                              subStep.mainSection.content.max
                                            "
                                            :step="
                                              subStep.mainSection.content.step
                                            "
                                            hint=""
                                            label=""
                                            persistent-hint
                                            thumb-label="always"
                                            :color="colorSlider"
                                            ticks
                                            :disabled="
                                              randomAvatarSelected
                                                ? true
                                                : false
                                            "
                                          ></v-slider>
                                          <v-range-slider
                                            id="informativeSlider"
                                            :tick-labels="
                                              subStep.mainSection.content
                                                .ticksLabels
                                            "
                                            v-model="
                                              subStep.mainSection.content.range
                                            "
                                            min="4"
                                            max="12"
                                            step="2"
                                            tick-size="5"
                                            ticks
                                            :disabled="
                                              randomAvatarSelected
                                                ? true
                                                : false
                                            "
                                          >
                                          </v-range-slider>
                                        </p>
                                        <v-row>
                                          <v-col cols="4">
                                            <p class="pt-5 pb-5 text-center">
                                              <v-img
                                                lazy-src="@/assets/images/BMI.jpg"
                                                max-height="100"
                                                max-width="250"
                                                src="@/assets/images/BMI.jpg"
                                              ></v-img>
                                            </p>
                                          </v-col>
                                          <v-col cols="8">
                                            <h6
                                              v-if="
                                                randomAvatarSelected === false
                                              "
                                              class="pt-5 pb-5 text-left"
                                              v-html=""
                                            >
                                              {{
                                                subStep.mainSection.content
                                                  .subText
                                              }}
                                              {{ bmiLevel }}
                                            </h6>
                                          </v-col>
                                        </v-row>
                                      </div>
                                    </v-card-text>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'text'
                                    "
                                  >
                                    <v-container>
                                      <v-row>
                                        <v-col cols="12">
                                          <div v-if="patientProfile">
                                            <v-card>
                                              <v-card-title
                                                >Medical History</v-card-title
                                              >
                                              <v-card-text
                                                v-html="medicalHistory"
                                              ></v-card-text>
                                            </v-card>
                                            <v-card>
                                              <v-card-title
                                                >Treatment
                                                Regiment</v-card-title
                                              >
                                              <v-card-text
                                                v-html="treatmentRegimen"
                                              ></v-card-text>
                                            </v-card>
                                            <v-card>
                                              <v-card-title
                                                >Lab results</v-card-title
                                              >
                                              <v-card-text
                                                v-html="labResults"
                                              ></v-card-text>
                                            </v-card>
                                          </div>
                                          <div v-else>
                                            <br />
                                            <h6
                                              class="pt-5 pb-5 text-center"
                                              v-html="
                                                subStep.mainSection.content
                                                  .mainText
                                              "
                                            ></h6>
                                            <br />
                                            <p class="mr-5 ml-5"></p>
                                          </div>
                                        </v-col>
                                      </v-row>
                                    </v-container>
                                  </v-card>
                                  <v-card
                                    outlined
                                    tile
                                    color="transparent"
                                    v-else-if="
                                      subStep.mainSection.content.type ===
                                        'preSDoH'
                                    "
                                  >
                                    <div style="height: 400px">
                                      <v-row>
                                        <v-col class="d-flex justify-center">
                                          <h6>
                                            Social determinants of health (SDoH)
                                            can impact treatmemnt trajectory and
                                            positive patient outcomes. Do you
                                            feel that your patient is affected
                                            by SDoh?
                                          </h6>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="12"
                                          class="d-flex justify-center"
                                        >
                                          <v-img
                                            lazy-src="@/assets/images/sdoh.jpg"
                                            max-height="350"
                                            max-width="350"
                                            contain
                                            src="@/assets/images/sdoh.jpg"
                                          ></v-img>
                                        </v-col>
                                      </v-row>
                                      <v-row>
                                        <v-col
                                          cols="6"
                                          class="d-flex justify-center"
                                        >
                                          <v-btn
                                            class="white--text caption font-weight-bold"
                                            large
                                            elevation="2"
                                            rounded
                                            color="orange"
                                            @click="hasSDoH(true)"
                                            >Yes
                                          </v-btn>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          class="d-flex justify-center"
                                        >
                                          <v-btn
                                            class="white--text caption font-weight-bold"
                                            large
                                            elevation="2"
                                            rounded
                                            color="orange"
                                            @click="hasSDoH(false)"
                                            >No
                                          </v-btn>
                                        </v-col>
                                        <v-col
                                          cols="6"
                                          class="d-flex justify-center"
                                          ><h6 v-show="false">
                                            you have selected {{ SDoH }}
                                          </h6></v-col
                                        >
                                      </v-row>
                                    </div>
                                  </v-card>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col
                              v-if="subStep.hasRightSection"
                              :md="!subStep.hasLeftSection ? 4 : 4"
                              :lg="!subStep.hasLeftSection ? 4 : 4"
                              :xl="!subStep.hasLeftSection ? 4 : 4"
                              align-self="center"
                              class="d-flex justify-center"
                            >
                              <v-card
                                class="flex-card"
                                height="100%"
                                v-if="subStep.rightSection.avatarSection"
                              >
                                <v-card-title
                                  class="justify-center"
                                  v-show="showAvatar"
                                  v-if="subStep.rightSection.hasTitle"
                                >
                                  {{ subStep.rightSection.title }}
                                </v-card-title>
                                <v-divider></v-divider>
                                <v-card-subtitle
                                  v-show="showAvatar"
                                  v-if="subStep.rightSection.hasSubtitle"
                                >
                                  {{ subStep.rightSection.subTitle }}
                                </v-card-subtitle>
                                <v-card-text v-show="showAvatar">
                                  <v-container fluid>
                                    <v-row>
                                      <v-col
                                        cols="12"
                                        class="d-flex justify-center"
                                      >
                                        <v-card
                                          max-height="250"
                                          max-width="188"
                                        >
                                          <v-img
                                            :lazy-src="
                                              subStep.id === 1
                                                ? avatarUrl
                                                : avatarRealUrl
                                            "
                                            max-height="250"
                                            max-width="188"
                                            width="188"
                                            height="250"
                                            contain
                                            :src="
                                              subStep.id === 1
                                                ? avatarUrl
                                                : avatarRealUrl
                                            "
                                          ></v-img>
                                        </v-card>
                                      </v-col>
                                    </v-row>
                                    <v-row>
                                      <v-col
                                        ><h6 class="text-center">
                                          {{ avatarName }},
                                          {{ avatarDescription }}
                                        </h6></v-col
                                      >
                                    </v-row>
                                    <v-row>
                                      <v-col v-if="currentSubStep === 1">
                                        <p
                                          class="text-justify"
                                          v-html="avatarBio"
                                        ></p>
                                      </v-col>
                                      <v-col v-else>
                                        <p
                                          class="text-justify"
                                          v-html="avatarBio"
                                        ></p>
                                        <v-expansion-panels focusable>
                                          <v-expansion-panel
                                            key="1"
                                            :disabled="!patientProfile"
                                          >
                                            <v-expansion-panel-header
                                              >Medical
                                              History</v-expansion-panel-header
                                            >
                                            <v-expansion-panel-content key="1">
                                              <p v-html="medicalHistory"></p>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                          <v-expansion-panel
                                            key="2"
                                            :disabled="!patientProfile"
                                          >
                                            <v-expansion-panel-header
                                              >Treatment
                                              Regiment</v-expansion-panel-header
                                            >
                                            <v-expansion-panel-content key="2">
                                              <p v-html="treatmentRegimen"></p>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                          <v-expansion-panel
                                            key="3"
                                            :disabled="!patientProfile"
                                          >
                                            <v-expansion-panel-header
                                              >Lab
                                              Results</v-expansion-panel-header
                                            >
                                            <v-expansion-panel-content key="3">
                                              <p v-html="labResults"></p>
                                            </v-expansion-panel-content>
                                          </v-expansion-panel>
                                        </v-expansion-panels>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-divider></v-divider>
                                <v-card-actions
                                  v-show="showAvatar"
                                  class="justify-center"
                                  v-if="subStep.rightSection.hasActions"
                                >
                                  <v-dialog
                                    v-if="currentSubStep > 1"
                                    v-model="dialog"
                                    persistent
                                    max-width="600px"
                                  >
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        color="yellow"
                                        fab
                                        small
                                        v-bind="attrs"
                                        v-on="on"
                                        class="ml-2 mr-2"
                                      >
                                        <v-icon color="green darken-2">
                                          mdi-pencil
                                        </v-icon>
                                      </v-btn>
                                    </template>
                                    <v-card>
                                      <v-card-title>
                                        <span class="text-h5">Notes</span>
                                      </v-card-title>
                                      <v-card-text>
                                        <v-container>
                                          <v-row>
                                            <v-col cols="12">
                                              <v-textarea
                                                filled
                                                label="Insert your notes"
                                                required
                                                v-model="currentNote"
                                              ></v-textarea>
                                            </v-col>
                                          </v-row>
                                        </v-container>
                                      </v-card-text>
                                      <v-card class="mx-auto" tile>
                                        <v-list shaped v-if="notes.length > 0">
                                          <v-subheader
                                            >List of notes</v-subheader
                                          >
                                          <v-list-item-group
                                            v-model="selectedNote"
                                            color="success"
                                          >
                                            <v-list-item
                                              v-for="(note, i) in notes"
                                              :key="i"
                                            >
                                              <v-list-item-icon>
                                                <v-icon
                                                  v-text="note.icon"
                                                ></v-icon>
                                              </v-list-item-icon>
                                              <v-list-item-content>
                                                <v-list-item-title
                                                  @click="selectedNoteList(i)"
                                                  v-text="note.text"
                                                >
                                                </v-list-item-title>
                                              </v-list-item-content>
                                            </v-list-item>
                                          </v-list-item-group>
                                        </v-list>
                                      </v-card>
                                      <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="dialog = false"
                                        >
                                          Close
                                        </v-btn>
                                        <v-btn
                                          color="blue darken-1"
                                          text
                                          @click="saveNotes"
                                        >
                                          Save
                                        </v-btn>
                                      </v-card-actions>
                                    </v-card>
                                  </v-dialog>
                                  <v-btn
                                    v-show="currentSubStep != 1"
                                    class="white--text caption font-weight-bold"
                                    large
                                    elevation="2"
                                    rounded
                                    color="orange"
                                    @click="
                                      previousStep(currentStep, currentSubStep)
                                    "
                                  >
                                    Back
                                  </v-btn>
                                  <v-btn
                                    class="white--text caption font-weight-bold"
                                    large
                                    elevation="2"
                                    rounded
                                    color="orange"
                                    @click="
                                      nextStep(currentStep, currentSubStep)
                                    "
                                  >
                                    Continue (TBD)
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                              <v-card
                                v-else
                                outlined
                                tile
                                class="flex-card"
                                height="100%"
                              >
                                <v-card-title
                                  v-if="subStep.rightSection.hasTitle"
                                >
                                  {{ subStep.rightSection.title }}
                                </v-card-title>
                                <v-card-subtitle
                                  v-if="subStep.rightSection.hasSubTitle"
                                >
                                  {{ subStep.rightSection.subtitle }}
                                </v-card-subtitle>
                                <v-card-text>
                                  <v-container fluid>
                                    <v-row>
                                      <v-col
                                        v-for="(content, c) in subStep
                                          .rightSection.content"
                                        class="d-flex justify-center"
                                      >
                                        <v-img
                                          v-if="content.type === 'image'"
                                          :lazy-src="content.url"
                                          max-height="250"
                                          max-width="250"
                                          :src="content.url"
                                        ></v-img>
                                        <p
                                          v-if="content.type === 'text'"
                                          class="pt-5 pb-5 font-weight-medium"
                                          v-html="content.text"
                                        ></p>
                                      </v-col>
                                    </v-row>
                                  </v-container>
                                </v-card-text>
                                <v-card-actions
                                  v-for="action in subStep.rightSection.actions"
                                  class="d-flex justify-center"
                                >
                                  <v-btn
                                    class="white--text caption font-weight-bold"
                                    large
                                    elevation="2"
                                    rounded
                                    color="orange"
                                    @click="actionsRightSection(action.action)"
                                  >
                                    {{ action.label }}
                                  </v-btn>
                                </v-card-actions>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card-text>
                      <!--Navigation-->
                      <v-card-actions>
                        <v-chip-group>
                          <div v-for="(item, i) in steps">
                            <v-chip
                              :key="i"
                              :color="
                                chips.includes(item.name)
                                  ? 'green darken-2'
                                  : 'gray'
                              "
                              :text-color="
                                chips.includes(item.name) ? 'white' : 'gray'
                              "
                              :disabled="chipsCompleted.includes(item.name)"
                            >
                              {{ item.name }}
                            </v-chip>
                            <v-chip
                              v-if="
                                item.hasSubSteps &&
                                  subChips.includes(
                                    item.name + ' ' + subItem.name
                                  )
                              "
                              v-for="(subItem, s) in item.subSteps"
                              :color="
                                subChips.includes(
                                  item.name + ' ' + subItem.name
                                )
                                  ? 'green lighten-1'
                                  : 'gray'
                              "
                              :text-color="
                                subChips.includes(
                                  item.name + ' ' + subItem.name
                                )
                                  ? 'white'
                                  : 'gray'
                              "
                            >
                              {{ subItem.id }}
                            </v-chip>
                          </div>
                        </v-chip-group>
                      </v-card-actions>
                      <!--End Navigation-->
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </div>
        </div>
      </v-card>
      <!--End show steps-->
    </v-container>
  </div>
  <!--END DESKTOP DESIGN-->
  <!--RESPONSIVE DESIGN-->
  <div v-else>
    <!--Responsive introduction-->
    <v-card class="d-flex" v-if="introduction.active">
      <v-row>
        <v-col>
          <v-container>
            <v-card class="d-flex">
              <v-container>
                <v-row no-gutters dense>
                  <v-col xs="12" sm="12" align-self="stretch">
                    <p
                      class="ml-5 mr-5 text-center"
                      v-html="introduction.rightSection.text1"
                    ></p>
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <v-row no-gutters dense>
                  <v-col
                    xs="4"
                    sm="4"
                    align-self="center"
                    class="d-flex justify-center"
                  >
                    <v-img
                      lazy-src="@/assets/images/logo.jpg"
                      max-height="100"
                      max-width="100"
                      src="@/assets/images/logo.jpg"
                    ></v-img>
                  </v-col>
                  <v-col xs="8" sm="8" align-self="center">
                    <p
                      class="mt-5 mr-b"
                      v-html="introduction.rightSection.text2"
                    ></p>
                  </v-col>
                </v-row>
              </v-container>
            </v-card>
            <v-card>
              <v-card-text transition="slide-x-transition">
                <div
                  class="wistia_responsive_padding"
                  style="padding:56.25% 0 0 0;position:relative;"
                >
                  <div
                    class="wistia_responsive_wrapper"
                    style="height:100%;left:0;position:absolute;top:0;width:100%;"
                  >
                    <iframe
                      src="https://fast.wistia.net/embed/iframe/51usxpb0f1?videoFoam=true"
                      title=" [Example Video] Wistia Video Essentials"
                      allow="autoplay; fullscreen"
                      allowtransparency="true"
                      frameborder="0"
                      scrolling="no"
                      class="wistia_embed"
                      name="wistia_embed"
                      msallowfullscreen
                      width="100%"
                      height="100%"
                    ></iframe>
                  </div>
                </div>
              </v-card-text>
            </v-card>
            <v-card d-flex>
              <v-card-text>
                <p class="text-right">
                  <v-btn
                    class="white--text caption font-weight-bold"
                    small
                    elevation="2"
                    rounded
                    color="orange"
                    @click="start()"
                  >
                    {{ introduction.action }}
                  </v-btn>
                </p>
              </v-card-text>
            </v-card>
          </v-container>
        </v-col>
      </v-row>
    </v-card>
    <!--End responsive introduction -->
    <!--Responsive steps-->
    <v-card outlined tile class="d-flex" height="100%" v-else>
      <v-card-text>
        <div v-if="step.id === currentStep" v-for="(step, n) in steps">
          <div
            v-if="step.hasSubSteps && subStep.id === currentSubStep"
            v-for="(subStep, s) in step.subSteps"
          >
            <v-container>
              <v-row no-gutters dense>
                <v-col>
                  <h6 class="mr-4 ml-4">{{ subStep.mainSection.title }}</h6>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <!--Responsive left section-->
              <v-row v-if="subStep.hasLeftSection" no-gutters dense>
                <v-col
                  xs="3"
                  sm="3"
                  align-self="center"
                  class="d-flex justify-center"
                >
                  <v-img
                    lazy-src="@/assets/images/logo.jpg"
                    max-height="100"
                    max-width="100"
                    src="@/assets/images/logo.jpg"
                  ></v-img>
                </v-col>
                <v-col xs="9" sm="9" align-self="center">
                  <p
                    v-for="content in subStep.leftSection.content"
                    class="mt-5 mr-5 text-small"
                    v-html="content.text"
                  ></p>
                  <p
                    v-for="action in subStep.leftSection.actions"
                    class="d-flex justify-center"
                  >
                    <v-btn
                      class="white--text caption font-weight-bold"
                      small
                      elevation="2"
                      rounded
                      color="orange"
                      @click="actionsLeftSection(action.action)"
                    >
                      {{ action.label }}
                    </v-btn>
                  </p>
                </v-col>
              </v-row>
              <!--End responsive left section-->
              <v-divider></v-divider>
              <!--Responsive right section-->
              <v-row
                no-gutters
                dense
                v-if="
                  subStep.hasRightSection && subStep.rightSection.avatarSection
                "
                v-show="showAvatar"
              >
                <v-col v-show="showAvatar" class="d-flex justify-center">
                  <h6 class="mt-2 text-center">
                    {{ subStep.rightSection.title }}
                  </h6>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                dense
                v-if="
                  subStep.hasRightSection && subStep.rightSection.avatarSection
                "
                v-show="showAvatar"
              >
                <v-col
                  v-if="currentSubStep === 1"
                  xs="9"
                  sm="9"
                  align-self="center"
                  class="d-flex justify-center"
                >
                  <p class="text-SMALL" v-html="avatarBio"></p>
                </v-col>
                <v-col v-else>
                  <p class="text-SMALL" v-html="avatarBio"></p>
                  <v-expansion-panels focusable>
                    <v-expansion-panel key="1" :disabled="!patientProfile">
                      <v-expansion-panel-header
                        >Medical History</v-expansion-panel-header
                      >
                      <v-expansion-panel-content key="1">
                        <p class="text-SMALL" v-html="medicalHistory"></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="2" :disabled="!patientProfile">
                      <v-expansion-panel-header
                        >Treatment Regiment</v-expansion-panel-header
                      >
                      <v-expansion-panel-content key="2">
                        <p class="text-SMALL" v-html="treatmentRegimen"></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel key="3" :disabled="!patientProfile">
                      <v-expansion-panel-header
                        >Lab Results</v-expansion-panel-header
                      >
                      <v-expansion-panel-content key="3">
                        <p class="text-SMALL" v-html="labResults"></p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
                <v-col
                  xs="3"
                  sm="3"
                  align-self="center"
                  class="d-flex justify-center"
                >
                  <v-container>
                    <v-row no-gutters dense>
                      <v-col
                        cols="12"
                        align-self="start"
                        class="d-flex justify-center"
                      >
                        <v-card max-height="150" max-width="113">
                          <v-img
                            :lazy-src="
                              subStep.id === 1 ? avatarUrl : avatarRealUrl
                            "
                            max-height="150"
                            height="150"
                            width="113"
                            max-width="113"
                            :src="subStep.id === 1 ? avatarUrl : avatarRealUrl"
                          ></v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row no-gutters dense>
                      <v-col>
                        <h6 class="text-center">
                          {{ avatarName }}<br />{{ avatarAge }} yo,
                          {{ avatarLocation }}
                        </h6>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-col>
              </v-row>
              <v-divider></v-divider>
              <v-container>
                <!--End responsive right section-->
                <v-row
                  no-gutters
                  dense
                  v-if="
                    subStep.mainSection.content.type ===
                      'selectorComorbidity' && showComorbidity
                  "
                >
                  <v-col
                    cols="12"
                    class="d-flex justify-center"
                    v-if="randomAvatarSelected === false"
                  >
                    <h6 class="text-center">
                      You have selected {{ comorbiditySelected }} comorbidity
                    </h6>
                  </v-col>
                  <v-col cols="12" v-else class="d-flex justify-center">
                    <h6 class="text-center">
                      The randomizer has selected
                      {{ comorbiditySelected }} comorbidity
                    </h6>
                  </v-col>
                </v-row>
                <v-row no-gutters dense v-else-if="showMB">
                  <v-col class="d-flex justify-center">
                    <h6>
                      You have selected {{ MBSelected }} modifiable behaviour
                    </h6>
                  </v-col>
                </v-row>
                <v-row no-gutters dense v-else-if="showSDoH">
                  <v-col class="d-flex justify-center">
                    <h6>
                      You have selected {{ SDoHSelected }} social determinant of
                      health
                    </h6>
                  </v-col>
                </v-row>
                <v-row no-gutters dense v-else-if="showGLP1">
                  <v-col class="d-flex justify-center">
                    <h6>You have selected {{ GLP1Selected }} GLP-1 RA</h6>
                  </v-col>
                </v-row>
              </v-container>
              <!--Responsive main content-->
              <v-row>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  v-if="subStep.mainSection.content.type === 'selector'"
                >
                  <v-card
                    max-height="150"
                    max-width="100"
                    @click="selectAvatar(avatar.id, false)"
                  >
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="100"
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  v-if="
                    subStep.mainSection.content.type ===
                      'selectorComorbidity' && randomAvatarSelected === false
                  "
                >
                  <v-card
                    style="border:2px solid red;border-radius: 25px;"
                    @click="
                      selectComorbidity(
                        avatar.id,
                        avatar.name,
                        avatar.video1,
                        avatar.video2
                      )
                    "
                  >
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="100"
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  class="d-flex justify-center"
                  v-if="
                    subStep.mainSection.content.type ===
                      'selectorComorbidity' && randomAvatarSelected === true
                  "
                >
                  <v-card style="border:2px solid red;border-radius: 25px;">
                    <v-img
                      :lazy-src="imageComorbidiy"
                      max-height="150"
                      max-width="100"
                      :src="imageComorbidiy"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  v-if="subStep.mainSection.content.type === 'selectorMB'"
                >
                  <v-card @click="selectMB(avatar.id, avatar.name)">
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="250"
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  v-if="subStep.mainSection.content.type === 'selectorSDoH'"
                >
                  <v-card @click="selectSDoH(avatar.id, avatar.name)">
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="250"
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col
                  cols="4"
                  v-for="avatar in subStep.mainSection.content.avatars"
                  v-if="subStep.mainSection.content.type === 'selectorGLP1'"
                >
                  <v-card @click="selectGLP1(avatar.id, avatar.name)">
                    <v-img
                      :lazy-src="avatar.url"
                      max-height="150"
                      max-width="250"
                      :src="avatar.url"
                    ></v-img>
                  </v-card>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'video'">
                  <div
                    class="wistia_responsive_padding"
                    style="padding:56.25% 0 0 0;position:relative;"
                  >
                    <div
                      class="wistia_responsive_wrapper"
                      style="height:100%;left:0;position:absolute;top:0;width:100%;"
                    >
                      <iframe
                        :src="
                          subStep.mainSection.content.conditional === false
                            ? subStep.mainSection.content.url
                            : selectorSpecificVideo
                        "
                        :title="subStep.mainSection.content.title"
                        allow="autoplay; fullscreen"
                        allowtransparency="true"
                        frameborder="0"
                        scrolling="no"
                        class="wistia_embed"
                        name="wistia_embed"
                        msallowfullscreen
                        width="100%"
                        height="100%"
                      ></iframe>
                    </div>
                  </div>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'videoGLP1'">
                  <v-row no-gutters dense>
                    <v-col>
                      <div
                        class="wistia_responsive_padding"
                        style="padding:56.25% 0 0 0;position:relative;"
                      >
                        <div
                          class="wistia_responsive_wrapper"
                          style="height:100%;left:0;position:absolute;top:0;width:100%;"
                        >
                          <iframe
                            :src="
                              subStep.mainSection.content.conditional === false
                                ? subStep.mainSection.content.url
                                : selectorSpecificVideo
                            "
                            :title="subStep.mainSection.content.title"
                            allow="autoplay; fullscreen"
                            allowtransparency="true"
                            frameborder="0"
                            scrolling="no"
                            class="wistia_embed"
                            name="wistia_embed"
                            msallowfullscreen
                            width="100%"
                            height="100%"
                          ></iframe>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      ><h6>
                        Do you wish to continue with this agent or choose a
                        different one?. Select your preferred agent before
                        continuing.
                      </h6></v-col
                    >
                  </v-row>
                  <v-row>
                    <v-col cols="6" class="d-flex justify-center">
                      <v-btn
                        class="white--text caption font-weight-bold"
                        large
                        elevation="2"
                        rounded
                        color="orange"
                        @click="btnGLP1(true)"
                        >Yes
                      </v-btn>
                    </v-col>
                    <v-col cols="6" class="d-flex justify-center">
                      <v-btn
                        class="white--text caption font-weight-bold"
                        large
                        elevation="2"
                        rounded
                        color="orange"
                        @click="btnGLP1(false)"
                        >No
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'scaleA1C'">
                  <div style="height: 400px">
                    <br />
                    <h6
                      v-if="randomAvatarSelected === false"
                      class="pt-5 pb-5 text-center"
                      v-html="subStep.mainSection.content.mainText"
                    ></h6>
                    <br />
                    <p class="mr-5 ml-5">
                      <v-slider
                        @change="changeSliderA1C"
                        v-model="a1cLevel"
                        :rules="subStep.mainSection.content.rules"
                        :min="subStep.mainSection.content.min"
                        :max="subStep.mainSection.content.max"
                        :step="subStep.mainSection.content.step"
                        hint=""
                        label=""
                        persistent-hint
                        thumb-label="always"
                        :color="colorSlider"
                        ticks
                        :disabled="randomAvatarSelected ? true : false"
                      ></v-slider>
                      <v-range-slider
                        id="informativeSlider"
                        :tick-labels="subStep.mainSection.content.ticksLabels"
                        v-model="subStep.mainSection.content.range"
                        min="4"
                        max="12"
                        step="2"
                        tick-size="5"
                        ticks
                        :disabled="randomAvatarSelected ? true : false"
                      >
                      </v-range-slider>
                    </p>
                    <v-row>
                      <v-col cols="4">
                        <p class="pt-5 pb-5 text-center">
                          <v-img
                            lazy-src="@/assets/images/A1C.jpg"
                            max-height="100"
                            max-width="250"
                            src="@/assets/images/A1C.jpg"
                          ></v-img>
                        </p>
                      </v-col>
                      <v-col cols="8">
                        <h6
                          v-if="randomAvatarSelected === false"
                          class="pt-5 pb-5 text-left"
                        >
                          {{ subStep.mainSection.content.subText }}
                          {{ a1cLevel }}
                        </h6>
                        <h6 v-else class="pt-5 pb-5 text-left">
                          The randomizer has selected a value of {{ a1cLevel }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'scaleBMI'">
                  <div style="height: 400px">
                    <br />
                    <h6
                      v-if="randomAvatarSelected === false"
                      class="pt-5 pb-5 text-center"
                      v-html="subStep.mainSection.content.mainText"
                    ></h6>
                    <br />
                    <br />
                    <p class="mr-5 ml-5">
                      <v-slider
                        @change="changeSliderBMI"
                        v-model="bmiLevel"
                        :rules="subStep.mainSection.content.rules"
                        :min="subStep.mainSection.content.min"
                        :max="subStep.mainSection.content.max"
                        :step="subStep.mainSection.content.step"
                        hint=""
                        label=""
                        persistent-hint
                        thumb-label="always"
                        :color="colorSlider"
                        ticks
                        :disabled="randomAvatarSelected ? true : false"
                      ></v-slider>
                      <v-range-slider
                        id="informativeSlider"
                        :tick-labels="subStep.mainSection.content.ticksLabels"
                        v-model="subStep.mainSection.content.range"
                        min="4"
                        max="12"
                        step="2"
                        tick-size="5"
                        ticks
                        :disabled="randomAvatarSelected ? true : false"
                      >
                      </v-range-slider>
                    </p>
                    <v-row>
                      <v-col cols="4">
                        <p class="pt-5 pb-5 text-center">
                          <v-img
                            lazy-src="@/assets/images/BMI.jpg"
                            max-height="100"
                            max-width="250"
                            src="@/assets/images/BMI.jpg"
                          ></v-img>
                        </p>
                      </v-col>
                      <v-col cols="8">
                        <h6
                          v-if="randomAvatarSelected === false"
                          class="pt-5 pb-5 text-left"
                        >
                          {{ subStep.mainSection.content.subText }}
                          {{ bmiLevel }}
                        </h6>
                        <h6 v-else class="pt-5 pb-5 text-left">
                          The randomizer has selected a value of {{ bmiLevel }}
                        </h6>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'text'">
                  <v-card>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <div v-if="patientProfile">
                            <v-card>
                              <v-card-title>Medical History</v-card-title>
                              <v-card-text
                                v-html="medicalHistory"
                              ></v-card-text>
                            </v-card>
                            <v-card>
                              <v-card-title>Treatment Regiment</v-card-title>
                              <v-card-text
                                v-html="treatmentRegimen"
                              ></v-card-text>
                            </v-card>
                            <v-card>
                              <v-card-title>Lab results</v-card-title>
                              <v-card-text v-html="labResults"></v-card-text>
                            </v-card>
                          </div>
                          <div v-else>
                            <br />
                            <h6
                              class="pt-5 pb-5 text-center"
                              v-html="subStep.mainSection.content.mainText"
                            ></h6>
                            <br />
                            <p class="mr-5 ml-5"></p>
                          </div>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-col>
                <v-col v-if="subStep.mainSection.content.type === 'preSDoH'">
                  <v-card>
                    <v-row>
                      <v-col class="d-flex justify-center">
                        <h6>
                          Social determinants of health (SDoH) can impact
                          treatmemnt trajectory and positive patient outcomes.
                          Do you feel that your patient is affected by SDoh?
                        </h6>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" class="d-flex justify-center">
                        <v-img
                          lazy-src="@/assets/images/sdoh.jpg"
                          max-height="350"
                          max-width="350"
                          contain
                          src="@/assets/images/sdoh.jpg"
                        ></v-img>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="6" class="d-flex justify-center">
                        <v-btn
                          class="white--text caption font-weight-bold"
                          large
                          elevation="2"
                          rounded
                          color="orange"
                          @click="hasSDoH(true)"
                          >Yes
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-center">
                        <v-btn
                          class="white--text caption font-weight-bold"
                          large
                          elevation="2"
                          rounded
                          color="orange"
                          @click="hasSDoH(false)"
                          >No
                        </v-btn>
                      </v-col>
                      <v-col cols="6" class="d-flex justify-center"
                        ><h6 v-show="false">you have selected {{ SDoH }}</h6>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
              <!--End responsive main content-->
              <!--Navigation section-->
              <v-row no-gutters dense>
                <v-col cols="12">
                  <v-chip-group show-arrows class="m-0">
                    <div v-for="(item, i) in steps">
                      <v-chip
                        :key="i"
                        :color="
                          chips.includes(item.name) ? 'green darken-2' : 'gray'
                        "
                        :text-color="
                          chips.includes(item.name) ? 'white' : 'gray'
                        "
                        :disabled="chipsCompleted.includes(item.name)"
                      >
                        {{ item.name }}
                      </v-chip>
                      <v-chip
                        v-if="
                          item.hasSubSteps &&
                            subChips.includes(item.name + ' ' + subItem.name)
                        "
                        v-for="(subItem, s) in item.subSteps"
                        :color="
                          subChips.includes(item.name + ' ' + subItem.name)
                            ? 'green lighten-1'
                            : 'gray'
                        "
                        :text-color="
                          subChips.includes(item.name + ' ' + subItem.name)
                            ? 'white'
                            : 'gray'
                        "
                      >
                        {{ subItem.id }}
                      </v-chip>
                    </div>
                  </v-chip-group>
                </v-col>
              </v-row>
              <!--End navigation section-->
              <v-divider></v-divider>
              <!--Actions section-->
              <v-row>
                <v-col cols="12" class="text-right">
                  <v-btn
                    v-show="currentSubStep != 1 && showAvatar"
                    class="white--text caption font-weight-bold"
                    small
                    elevation="2"
                    rounded
                    color="orange"
                    @click="previousStep(currentStep, currentSubStep)"
                  >
                    Back
                  </v-btn>
                  <v-btn
                    v-show="showAvatar"
                    right
                    class="ml-3 white--text caption font-weight-bold"
                    small
                    elevation="2"
                    rounded
                    color="orange"
                    @click="nextStep(currentStep, currentSubStep)"
                  >
                    Continue (TBD)
                  </v-btn>
                </v-col>
              </v-row>
              <!--End actions section-->
            </v-container>
            <v-container> </v-container>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <!--End responsive steps-->
  </div>
  <!--END RESPONSIVE DESIGN-->
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  metaInfo: {
    // title will be injected into parent titleTemplate
    title: "Stepper",
  },
  data() {
    return {
      currentSelector: 0,
      //navigation
      chips: [],
      subChips: [],
      chipsCompleted: [],
      //selectorSlider
      selectorLevel: "",
      selectorSpecificVideo: "",
      selectorSpecificVideoTmp: "",
      //slider
      colorSlider: "blue",
      //dialog notes
      dialog: false,
      //steps & substeps control
      currentStep: 1,
      currentSubStep: 1,
      //avatar section
      showAvatar: false,
      avatarBio: "",
      avatarName: "",
      avatarAge: "",
      avatarLocation: "",
      avatarSelected: 0,
      avatarUrl: "",
      avatarRealUrl: "",
      randomAvatarSelected: false,
      avatarDescription: "",
      //A1C
      a1cLevel: 0,
      //BMI
      bmiLevel: 0,
      //Comorbidity
      comorbiditySelected: 0,
      showComorbidity: false,
      imageComorbidiy: "",
      //MB
      MBSelected: 0,
      showMB: false,
      imageMB: "",
      //SDoH
      SDoH: false,
      SDoHSelected: 0,
      showSDoH: false,
      SDoHnoVideo: "",
      //GLP1
      GLP1Selected: 0,
      showGLP1: false,
      //Patient Profile
      patientProfile: false,
      medicalHistory: "",
      treatmentRegimen: "",
      labResults: "",
      items: [],
      //notes
      selectedNote: 1,
      currentNote: "",
      noteForUpdate: false,
      idNoteForUpdate: 0,
      notes: [],
      //track
      userId: 0,
      uuid: "",
      firstName: "",
      lastName: "",
      email: "",
      platform: "",
      learnerType: "",
      //introduction
      introduction: {
        active: false,
        leftSection: {
          image: "",
          text1:
            "Welcome to <b>A Glimpse at Optimal T2D Managment Enhancing Outcomes with GLP-1RAs</b>",
          text2:
            "Here's whatyou'll do:<br>Treat 3 patients<br>Spend some time in the Library<br>Collect some credit!",
        },
        mainSection: {
          type: "video",
          conditional: false,
          id: "51usxpb0f1",
          url: "https://fast.wistia.net/embed/iframe/51usxpb0f1?videoFoam=true",
          title: "Introduction video",
        },
        action: "Start treating patients",
      },
      //steps
      steps: [
        {
          id: 1,
          name: "CASE 1",
          description: "",
          hasSubSteps: true,
          subSteps: [
            {
              id: 1,
              name: "Avatar",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: true,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                    text: "",
                  },
                  {
                    type: "text",
                    url: "",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile selection",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selector",
                  avatar: true,
                  avatars: [
                    {
                      id: "1",
                      name: "Malcolm White",
                      firstname: "Malcom",
                      lastName: "White",
                      avatarDescription: "Male, African American, 53 YO",
                      shortProfile:
                        "Oil Rig Technician, Married to wife Sarah, father of 4",
                      a1CDefaultNum: "7.8",
                      bmiDefaultNum: "33",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "CVD",
                      medicalHistory:
                        "Malcolm's medical history: <p>Type 2 diabetes diagnosed 11 years ago, also has hypertension, hyperlipidemia; takes daily medications for all 3.  Recently experienced weight gain, occasional hypoglycemia.</p><p>Family history of T2D and heart disease including uncontrolled heart disease and heart failure (his father passed away from CVD prematurely at the age of 62); smokes on the job only, social EtoH.</p>",
                      patientComments:
                        '"I have a high stress job and am often away from home on the job site as an oil rig technician. While away for work, I don\'t have many healthy food options. When Im home with my wife and 4 teenage children, I enjoy spending time with them including backyard bbqs and pizza nights. My wife and I usually purchase and cook foods that the kids like. I try to stay active, but find it tough to be motivated after being at work for days or even weeks at a time."',
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg bid<br />Humalog with meals<br />Lantus at night<br />Rosuvastatin 10 mg QD<br />Metoprolol 50 mg BID<br />Furosemide 20 mg QD",
                      labResults:
                        "Lab Results<br />A1C = 7.8, BMI = 33<br />LDL = 145 mg/dL<br />TG = 185 mg/dL<br />HDL = 35 mg/dL<br />HR = 76 bpm<br />BP = 136 / 85 mm/Hg<br />eGFR = 65 ml/min/1.73m3 ",
                      modifiable_behaviour_default: "Nutrition",
                      sdoh_default: "Food Deserts",
                      therapy: "Semaglutide",
                      gender: "Male",
                      race: "African American",
                      age: "53",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_1.jpg"),
                      bio:
                        "Oil Rig Technician, Married to wife Sarah, father of 4",
                      idVideoSDoHNo: "roxlycl8pw",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/roxlycl8pw?videoFoam=true",
                    },
                    {
                      id: "2",
                      name: "Randa Abdi",
                      firstname: "Randa",
                      lastName: "Abdi",
                      avatarDescription: "Female, Somali American, 38 YO",
                      shortProfile:
                        "School Teacher, Married to husband Aden, mother of 2",
                      a1CDefaultNum: "7.5",
                      bmiDefaultNum: "30",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: true,
                      comorbidity_default_num: "CVD",
                      medicalHistory:
                        "Randa's medical history:<p>Type 2 diabetes diagnosed 5 years after having gestational diabetes and pre-eclampsia in her first pregnancy. She has also has hypertension and is taking medications for both conditions.</p><p>Family history of T2D and CVD; her mother passed away in her 50s from HF. Does not smoke or drink EtoH.</p>",
                      patientComments:
                        '"I work part-time as a nursery / kindergarten school teacher and my children are enrolled at the same school, which helps with managing my schedule. I enjoy cooking, especially traditional foods but it is difficult to find fresh ingredients where I live. My husband cooks on the weekends, but its often heavy foods and bbq meals. I worry about sodium and hypertentiosn because my mother passed away very young from heart disease."',
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg<br />Humalog with meals<br />Aspirin 81 mg QD<br />Metoprolol 50 mg BID<br />Furosemide 20 mg QD",
                      labResults:
                        "Lab Results<br />A1C = 7.5 BMI = 30<br />LDL = 110 mg/dL<br />TG = 160 mg/dL<br />HDL = 40mg/dL<br />Total cholesterol- 196mg/dl<br />BP = 137/ 84 mm/Hg",
                      modifiable_behaviour_default: "Nutrition",
                      sdoh_default: "Food Deserts",
                      therapy: "Semaglutide",
                      gender: "Female",
                      race: "Somali American",
                      age: "38",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_2.jpg"),
                      bio:
                        "School Teacher, Married to husband Aden, mother of 2",
                      idVideoSDoHNo: "ifg20fe4o2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/ifg20fe4o2?videoFoam=true",
                    },
                    {
                      id: "3",
                      name: "Camila Hernandez",
                      firstname: "Camila",
                      lastName: "Hernandez",
                      avatarDescription: "Female, Hispanic, 41 YO",
                      shortProfile:
                        "Software engineer, Married to husband Carlos, 3 children",
                      a1CDefaultNum: "8.5",
                      bmiDefaultNum: "28",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: true,
                      comorbidity_default_num: "PCOS",
                      medicalHistory:
                        "Camilla's medical history:<p>Type 2 diabetes diagnosed 7 years ago after her first pregnancy. Has also had PCOS since she was a teenager and her weight fluctuates due to hormonal imbalances. Recently experienced weight gain, thrush and uncontrolled glucose levels as metformin alone did not work for her. Family history of T2D. Does not smoke or drink EtoH. </p>",
                      patientComments:
                        '"I have a full time remote job that allows me to be home with my kids, but I find myself working long hours because I am interupted so often and have so much to do for 3 small children, especially my 3 year old who is not yet in school. My husband works long hours in the city and is often home late. I\'m either on my feet too long with the kids or sitting too long for my desk job. The stress levels keep me up at night, which I think has made my PCOS and T2D worse."',
                      treatmentRegimen:
                        "Rx regimen<br />SGLT2 (canagliflozin) 100 mg QD<br /> Metformin 750mg BID<br />Spironolactone 100 mg QD<br />Clomiphene 50mg QD<br />Yasmin 1 tablet QD",
                      labResults:
                        "Lab Results<br />A1C = 8.5 BMI = 28<br />TSH- 3.9 mIU/L<br />Serum hCG level- 3 mIU/mL<br />Glucose- 1880 mg/dL<br />Testosterone- 40 ng/ml<br />LDL = 110 mg/dL<br />TG = 160 mg/dL<br />HDL = 40mg/dL<br /> Total cholesterol- 196mg/dl<br />BP = 132/ 84 mm/Hg",
                      modifiable_behaviour_default: "Stress Management",
                      sdoh_default: "Built Enviro",
                      therapy: "Exenatide ER",
                      gender: "Female",
                      race: "Hispanic",
                      age: "41",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_3.jpg"),
                      bio:
                        "Software engineer, Married to husband Carlos, 3 children",
                      idVideoSDoHNo: "59hhhhili9",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/59hhhhili9?videoFoam=true",
                    },
                    {
                      id: "4",
                      name: "Tracy Marlowe",
                      firstname: "Tracy",
                      lastName: "Marlowe",
                      avatarDescription: "Transfemale, AA, 50 YO ",
                      shortProfile:
                        "Retail manager, Lives with partner David, no children",
                      a1CDefaultNum: "7.9",
                      bmiDefaultNum: "36",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "Thyroid",
                      medicalHistory:
                        "Tracy's medical history:<p>Type 2 diabetes diagnosed 15 years ago, began gender reassignment treatment 20 years ago ; takes daily medications for T2D , Graves disease, hormone therapy. Recently experienced weight gain, possibly worsened by Graves disease, and depression and binge eating to cope. Family history of T2D and heart disease. Social smoker and EtoH.</p>",
                      patientComments:
                        "\"I'm generally a healthy eater, and I'm on my feet all day at work. We normally eat out and don't cook. I don't like to exercise a lot and my partner and I live in a city apartment, so we don't have room for exercise equipment.\"",
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg bid<br />Glyburide 2.5 mg BID<br />Lantus at night<br />Sertraline 50 mg QD<br />Spironolactone 200 mg QD<br />Estradiol patch 0.1mg twice a week<br />Propranolol 40 mg QID<br />Methimazole 15mg QD",
                      labResults:
                        "Lab Results<br />A1C = 7.9 BMI = 36<br />LDL = 155 mg/dL<br />TG = 230 mg/dL<br />HDL = 35 mg/dL<br />HR = 76 bpm<br />BP = 136 / 85 mm/Hg<br />TSH- 4.1 mIU/L<br />T4- 0.9  mg/dL<br />T3- 62  ng/dL  ",
                      modifiable_behaviour_default: "Physical Activity",
                      sdoh_default: "None",
                      therapy: "Lixisenatide",
                      gender: "Transfemale",
                      race: "AA",
                      age: "50",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_4.jpg"),
                      bio:
                        "Retail manager, Lives with partner David, no children",
                      idVideoSDoHNo: "4ppp68w3j5",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4ppp68w3j5?videoFoam=true",
                    },
                    {
                      id: "5",
                      name: "David Chang",
                      firstname: "David",
                      lastName: "Chang",
                      avatarDescription:
                        "Male, Asian (Chinese) American, 46 YO",
                      shortProfile:
                        "Grocery store owner, Married to wife Lianne, 2 children",
                      a1CDefaultNum: "7.8",
                      bmiDefaultNum: "25",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "CKD",
                      medicalHistory:
                        "David's medical history:<p>Type 2 diabetes diagnosed about 10 years ago and recently diagnosed with early stage kidney disease and occasional hypertension and sleep apnea. Also has occasional hypoglycemia. Family history of T2D and CVD. Occasional EtoH and smoker.</p>",
                      patientComments:
                        "\"I was an accountant back home, but since moving to the US my wife and I have had to rebuild our professional lives as business owner.s It takes a lot of time and hard work and we can't afford a much help. My kids are teenagers now and focused on their studies so they don't have time to help in the store. Our health has not been a priority for some time now.\"",
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 500 mg bid<br />Sitagliptin 50 mg/BID<br />Lisinopril 10 mg QD<br />Erythropoietin 80 Units/kg 3 times weekly<br />Furosemide 40 mg QD<br />Losartan 25 mg QD<br />Lanthanum Carbonate 750 mg BID",
                      labResults:
                        "Lab Results<br />A1C = 7.8, BMI = 25<br />LDL = 155 mg/dL<br />TG = 160 mg/dL<br />HDL = 35 mg/dL<br />Total cholesterol- 196mg/dl<br />BP = 139/ 85 mm/Hg<br />UACR-30 mg/g<br />eGFR-59 mL/min/1.73m2<br />BUN-22 mg/dl<br />Potassium- 5.2 mEq/l ",
                      modifiable_behaviour_default: "Sleep / Circadian Rhythm",
                      sdoh_default: "Access",
                      therapy: "Liraglutide",
                      gender: "Male",
                      race: "Asian (Chinese) American",
                      age: "46",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_5.jpg"),
                      bio:
                        "Grocery store owner, Married to wife Lianne, 2 children",
                      idVideoSDoHNo: "4sbr3gswbd",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4sbr3gswbd?videoFoam=true",
                    },
                    {
                      id: "6",
                      name: "Glen Sammon",
                      firstname: "Glen",
                      lastName: "Sammon",
                      avatarDescription: "Transmale, White American, 48 YO",
                      shortProfile:
                        "Photographer, Married to wife Lisa, 1 biological child",
                      a1CDefaultNum: "8.2",
                      bmiDefaultNum: "35",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "Thyroid",
                      medicalHistory:
                        "Glenn's medical history:<p>Type 2 diabetes diagnosed 15 years ago, began gender reassignment treatment 20 years ago ; takes daily medications for T2D, hypothyroidism, and hormone therapy. History of bipolar depression. Vapes regularly, social EtoH.</p> ",
                      patientComments:
                        "\"I have a busy schedule as a wedding photographer, especially in the spring and summer. I usually eat out for lunch and skip breakfast. My partner and I love going out to eat often also. I'm on my feet often for work, so don't exercise. I try to remember to take all my meds, but its hard to keep a schedule.\"",
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg bid<br />Canagliflozin 100 mg QD<br />Lantus at night<br /> Levothyroxine 125 mcg QD<br />Testosterone transdermal system 4mg/day",
                      labResults:
                        "Lab Results<br />A1C = 8.2, BMI = 37<br />LDL = 155 mg/dL<br />TG = 230 mg/dL<br />HDL = 35 mg/dL<br />HR = 76 bpm<br />BP = 136 / 85 mm/Hg<br />TSH- 4.1 mIU/L<br />T4- 0.9 mg/dL<br />T3- 62 ng/dL ",
                      modifiable_behaviour_default: "Mental Health",
                      sdoh_default: "Built Enviro ",
                      therapy: "Liraglutide",
                      gender: "Transmale",
                      race: "White American",
                      age: "48",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_6.jpg"),
                      bio:
                        "Photographer, Married to wife Lisa, 1 biological child",
                      idVideoSDoHNo: "c3hzndrprj",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/c3hzndrprj?videoFoam=true",
                    },
                    {
                      id: "7",
                      name: "Robert Doyle",
                      firstname: "Robert",
                      lastName: "Doyle",
                      avatarDescription: "Male, White American, 67 YO",
                      shortProfile:
                        "Retired truck driver, married to wife, 4 children",
                      a1CDefaultNum: "8",
                      bmiDefaultNum: "30",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "NASH",
                      medicalHistory:
                        "Robert's medical history:<p>Type 2 diabetes diagnosed more than 20 years ago, also has NASH, hyperlipidemia; takes daily medications for all 3. Recently experienced weight gain, occasional hypoglycemia. Family history of T2D and CVD. Regular EtoH.</p>",
                      patientComments:
                        '"My wife and I are empty nesters and enjoy going out for dinner and a drink with friends. I enjoy regular drinks at the pub with friends. My wife and I are both now on Medicare and had to change doctors, even though Medicare covers much of our medical expenses. We usually eat frozen dinners or takeaways these days and my knee pain makes excercise difficult, except for an occasional stroll."',
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg bid<br />Glimepiride 2 mg QD<br />Lantus at night<br />Losartan 50 mg QD<br />Pentoxifylline 800 mg QD<br />Pioglitazone 30 mg QD<br />Simvastatin 20 mg QD",
                      labResults:
                        "Lab Results<br />A1C = 8, BMI = 30<br />LDL = 155 mg/dL<br />TG = 160 mg/dL<br />HDL = 35 mg/dL<br />Total cholesterol- 196mg/dl<br />BP = 139/ 85 mm/Hg<br />ALT- 75 u/l<br />AST- 55 u/l ",
                      modifiable_behaviour_default: "Smoking / EtoH Cessation",
                      sdoh_default: "None",
                      therapy: "Dulaglutide",
                      gender: "Male",
                      race: "White American",
                      age: "67",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_7.jpg"),
                      bio: "Retired truck driver, married to wife, 4 children",
                      idVideoSDoHNo: "iqvb82kz1v",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/iqvb82kz1v?videoFoam=true",
                    },
                    {
                      id: "8",
                      name: "Michelle Davis",
                      firstname: "Michelle",
                      lastName: "Davis",
                      avatarDescription: "Female, White American, 47 YO",
                      shortProfile:
                        "Nail Technician, Single (Divorced), mother of 3",
                      a1CDefaultNum: "8.4",
                      bmiDefaultNum: "34",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: true,
                      comorbidity_default_num: "Transplant",
                      medicalHistory:
                        "Michelle's medical history:<p>Type 2 diabetes diagnosed in her 20s after her 3rd pregnancy, and she has also struggled with obesity since her 20s. After bariatric surgery about 5 years ago, she lost considerable weight, but is still overweight and it has recently been difficult to manage her blood glucose levels. She also has chronic UTIs and occasional hypoglycemia.</p>",
                      patientComments:
                        '"I work almost every day and my job requires a lot of sitting. I usually get fast food for the kids after a long day, but since my surgery I have food restrictions and try to get healthier takeaway options. Its difficult doing it all as a single mom. I thought my bariatric surgery would solve my weight problems, but I still need more help." ',
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 500 mg<br />Dapagliflozin 5 mg QD<br />Glyburide 2.5 mg QD<br />Sirolimus 1 mg QD ",
                      labResults:
                        "Lab Results<br />A1C = 8 BMI = 33<br />LDL = 110 mg/dL<br />TG = 160 mg/dL<br />HDL = 40mg/dL<br />Total cholesterol- 196mg/dl<br />BP = 137/ 84 mm/Hg <br />Glucose- 210 mg/dl<br />BUN- 21 mg/dl<br />Creatinine- 1.3 mg/dl<br />Hematocrit- 39%<br />Potassium- 4.9 mEq/l<br />Albumin- 3.5 g/dl<br />ALT- 55 u/l ",
                      modifiable_behaviour_default: "Physical Activity",
                      sdoh_default: "Socioeconomic",
                      therapy: "Exenatide",
                      gender: "Female",
                      race: "White American",
                      age: "47",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_8.jpg"),
                      bio: "Nail Technician, Single (Divorced), mother of 3",
                      idVideoSDoHNo: "3jci9rkkp2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/3jci9rkkp2?videoFoam=true",
                    },
                    {
                      id: "9",
                      name: "Ryan Kekoa",
                      firstname: "Ryan",
                      lastName: "Kekoa",
                      avatarDescription:
                        "Male, Pacific Islander American, 30 YO",
                      shortProfile: "Restaurant Cook, Single, no children",
                      a1CDefaultNum: "9",
                      bmiDefaultNum: "37",
                      cardiovascular_ok: true,
                      renal_disease_ok: true,
                      endocrine_ok: true,
                      nafld_nash_ok: true,
                      post_surgery: true,
                      pcos_ok: false,
                      comorbidity_default_num: "CVD",
                      medicalHistory:
                        "Ryan's medical history: <p>Has been overweight since childhood and recently diagnosed with obesity. Type 2 diabetes and hypertension diagnosed almost 10 years ago. And takes daily medications for both. Recently experienced weight gain, occasional hypoglycemia. Family history of T2D and CVD. Regular EtoH. </p>",
                      patientComments:
                        '"I work all day and go out at night with friends quite often since Im single. I\'ve gained a lot of weight in my 20s and have a football knee injury that makes it hard to exercise sometime."',
                      treatmentRegimen:
                        "Rx regimen<br />Metformin 1000 mg bid<br />Humalog with meals<br />Lantus at night<br />Metoprolol 50 mg BID<br />Atorvastatin 20 mg QD<br />Furosemide 20 mg QD",
                      labResults:
                        "Lab Results<br />A1C = 9, BMI = 37<br />LDL = 155 mg/dL<br />TG = 230 mg/dL<br />HDL = 35 mg/dL<br />Total cholesterol- 240mg/dl<br />BP = 140/ 90 mm/Hg",
                      modifiable_behaviour_default: "Physical Activity",
                      sdoh_default: "Education",
                      therapy: "Dulaglutide",
                      gender: "Male",
                      race: "Pacific Islander American",
                      age: "30",
                      location: "",
                      url: require("@/assets/images/avatars/avatar_9.jpg"),
                      bio: "Restaurant Cook, Single, no children",
                      idVideoSDoHNo: "zpyc4c1ou4",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/zpyc4c1ou4?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 2,
              name: "Introduction Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | VIDEO",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "pxrzbkqabx",
                  url:
                    "https://fast.wistia.net/embed/iframe/pxrzbkqabx?videoFoam=true",
                  title: "Avatar video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 3,
              name: "A1C",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select A1C value",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "scaleA1C",
                  scale: true,
                  value: 8,
                  mainText:
                    "Select your patient's A1C using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a A1C\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "4",
                  max: "12",
                  step: "0.1",
                  range: [4, 12],
                  ticksLabels: ["4.0", "6.0", "8.0", "10.0", "12.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 4,
              name: "A1C General Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "A1C General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 5,
              name: "A1C Specific Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "tmrf458pl1",
                      url:
                        "https://fast.wistia.net/embed/iframe/tmrf458pl1?videoFoam=true",
                      title: "Low A1c video",
                    },
                    {
                      value: "medium",
                      id: "hfgqrdahv0",
                      url:
                        "https://fast.wistia.net/embed/iframe/hfgqrdahv0?videoFoam=true",
                      title: "Medium A1c video",
                    },
                    {
                      value: "high",
                      id: "9byj5tf894",
                      url:
                        "https://fast.wistia.net/embed/iframe/9byj5tf894?videoFoam=true",
                      title: "High A1c video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 6,
              name: "BMI",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select BMI value",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "scaleBMI",
                  scale: true,
                  value: 27,
                  mainText:
                    "Select your patient's BMI using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a BMI\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "25",
                  max: "45",
                  step: "0.1",
                  range: [25, 45],
                  ticksLabels: ["25.0", "30.0", "35.0", "40.0", "45.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 7,
              name: "BMI General Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "8tf3r4k328",
                  url:
                    "https://fast.wistia.net/embed/iframe/8tf3r4k328?videoFoam=true",
                  title: "BMI General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 8,
              name: "BMI Specific Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC BMI VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "bclku39c3c",
                      url:
                        "https://fast.wistia.net/embed/iframe/bclku39c3c?videoFoam=true",
                      title: "Low BMI video",
                    },
                    {
                      value: "medium",
                      id: "dkl15b5xhv",
                      url:
                        "https://fast.wistia.net/embed/iframe/dkl15b5xhv?videoFoam=true",
                      title: "Medium BMI video",
                    },
                    {
                      value: "high",
                      id: "jxwy82yp28",
                      url:
                        "https://fast.wistia.net/embed/iframe/jxwy82yp28?videoFoam=true",
                      title: "High BMI video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 9,
              name: "Comorbidities",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select Comorbidities",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorComorbidity",
                  avatar: true,
                  avatars: [
                    {
                      id: "10",
                      name: "CVD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CVD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/gipsa9ur4b?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/pm1w5onh1l?videoFoam=true",
                    },
                    {
                      id: "11",
                      name: "CKD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CKD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/i6f04iywt3?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/6wtjuvh37l?videoFoam=true",
                    },
                    {
                      id: "12",
                      name: "Thyroid",
                      url: require("@/assets/images/comorbidities/Comorbidity_Thyroid.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/b982p2ufzb?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/uags2tsaya?videoFoam=true",
                    },
                    {
                      id: "13",
                      name: "NASH",
                      url: require("@/assets/images/comorbidities/Comorbidity_NASH.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/omvnohs9c7?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/8oez5gduff?videoFoam=true",
                    },
                    {
                      id: "14",
                      name: "PCOS",
                      url: require("@/assets/images/comorbidities/Comorbidity_PCOS.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/v7k6f11jjc?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/o1bo0t7ezd?videoFoam=true",
                    },
                    {
                      id: "15",
                      name: "Transplant",
                      url: require("@/assets/images/comorbidities/Comorbidity_Transplant.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/ojlkcun39o?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/qgav4rf396?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 10,
              name: "Comorbidity Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 11,
              name: "Patient Profile",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile and Lab Section",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 12,
              name: "Patient Profile/Lab results Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 13,
              name: "Modifiable Behaviours",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable Behaviours",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorMB",
                  avatar: true,
                  avatars: [
                    {
                      id: "16",
                      name: "Nutrition",
                      url: require("@/assets/images/behaviours/BYOCS_Nutrition.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gssl05rrfd?videoFoam=true",
                    },
                    {
                      id: "17",
                      name: "PhysicalActivity",
                      url: require("@/assets/images/behaviours/BYOCS_PhysicalActivity.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/9grvi3stc6?videoFoam=true",
                    },
                    {
                      id: "18",
                      name: "MentalHealth",
                      url: require("@/assets/images/behaviours/BYOCS_MentalHealth.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/t7ycw3t5bi?videoFoam=true",
                    },
                    {
                      id: "19",
                      name: "StressManagement",
                      url: require("@/assets/images/behaviours/BYOCS_StressManagement.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gn1ko61026?videoFoam=true",
                    },
                    {
                      id: "20",
                      name: "Sleep",
                      url: require("@/assets/images/behaviours/BYOCS_Sleep.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/8xb8cavuu1?videoFoam=true",
                    },
                    {
                      id: "21",
                      name: "SmokingEtOH",
                      url: require("@/assets/images/behaviours/BYOCS_SmokingEtOH.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ec2o2l5vyo?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 14,
              name: "Modifiable Behaviour Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable behaviour video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 15,
              name: "Pre SDoH",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Social determinants of health",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "preSDoH",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 16,
              name: "SDoH No",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH No video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 17,
              name: "SDoH",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Social determinants of health",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorSDoH",
                  avatar: true,
                  avatars: [
                    {
                      id: "22",
                      name: "Education",
                      url: require("@/assets/images/sdoh/BYOCS_Icons_SDOH_Education_noBG.png"),
                      color: "background-color: green; border-radius: 25px;",
                      video:
                        "https://fast.wistia.net/embed/iframe/55c17ujy8z?videoFoam=true",
                    },
                    {
                      id: "23",
                      name: "Comunity Context",
                      url: require("@/assets/images/sdoh/BYOCS_Icons_SDOH_Social_noBG.png"),
                      color: "background-color: red; border-radius: 25px;",
                      video:
                        "https://fast.wistia.net/embed/iframe/59l7rp7rst?videoFoam=true",
                    },
                    {
                      id: "24",
                      name: "Economic Stability",
                      url: require("@/assets/images/sdoh/BYOCS_Icons_SDOH_Economic_noBG.png"),
                      color: "background-color: yellow; border-radius: 25px;",
                      video:
                        "https://fast.wistia.net/embed/iframe/1nmo6czq33?videoFoam=true",
                    },
                    {
                      id: "25",
                      name: "Health Care",
                      url: require("@/assets/images/sdoh/BYOCS_Icons_SDOH_Healthcare_noBG.png"),
                      color: "border:2px solid green; border-radius: 25px;",
                      video:
                        "https://fast.wistia.net/embed/iframe/zhk3af0fcf?videoFoam=true",
                    },
                    {
                      id: "26",
                      name: "Neighborhood",
                      url: require("@/assets/images/sdoh/BYOCS_Icons_SDOH_Build_noBG.png"),
                      color: "border:2px solid green; border-radius: 25px;",
                      video:
                        "https://fast.wistia.net/embed/iframe/2lw7tokuoq?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 18,
              name: "SDoH Si",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH Specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 19,
              name: "SDoH General Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH General video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "bo1c3cnxat",
                  url:
                    "https://fast.wistia.net/embed/iframe/bo1c3cnxat?videoFoam=true",
                  title: "SDoH General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 20,
              name: "GLP-1 RA",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select GLP-1 RA",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorGLP1",
                  avatar: true,
                  avatars: [
                    {
                      id: "26",
                      name: "SemaglitudeOral",
                      url: require("@/assets/images/GLP1/semaglutideOral_1.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/pc790cxxww?videoFoam=true",
                    },
                    {
                      id: "27",
                      name: "ExcenatideER",
                      url: require("@/assets/images/GLP1/excenatideER_2.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/vgulm729wa?videoFoam=true",
                    },
                    {
                      id: "28",
                      name: "Excenatide",
                      url: require("@/assets/images/GLP1/exenatide_3.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ldwtqnxzis?videoFoam=true",
                    },
                    {
                      id: "29",
                      name: "Liragutide",
                      url: require("@/assets/images/GLP1/liragutide_4.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/m8wuktlfuf?videoFoam=true",
                    },
                    {
                      id: "30",
                      name: "Semaglitude",
                      url: require("@/assets/images/GLP1/semaglutide_5.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/xz4zgvd38t?videoFoam=true",
                    },
                    {
                      id: "31",
                      name: "Dulaglutide",
                      url: require("@/assets/images/GLP1/dulaglutide_6.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/mbhemgpyhp?videoFoam=true",
                    },
                    {
                      id: "32",
                      name: "Lixisenatide",
                      url: require("@/assets/images/GLP1/lixisenatide_7.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/uyjtu6bglt?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 21,
              name: "GLP-1 RA Specific Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 22,
              name: "GLP-1 RA Specific Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 23,
              name: "Side Effects Video",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Side Efects Video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "Side Effects",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 24,
              name: "Library",
              description: "",
              active: true,
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: [],
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Library",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
          ],
        },
        {
          id: 2,
          name: "CASE 2",
          description: "",
          hasSubSteps: true,
          subSteps: [
            {
              id: 1,
              active: true,
              name: "Avatar",
              hasNavigation: true,
              hasLeftSection: true,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile selection",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selector",
                  avatar: true,
                  avatars: [
                    {
                      id: "1",
                      name: "avatar 1",
                      age: "64",
                      location: "Madison, Wi",
                      url: require("@/assets/images/avatars/avatar_1.jpg"),
                      bio:
                        "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "roxlycl8pw",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/roxlycl8pw?videoFoam=true",
                    },
                    {
                      id: "2",
                      name: "avatar 2",
                      age: "54",
                      location: "New Jersey, NY",
                      url: require("@/assets/images/avatars/avatar_2.jpg"),
                      bio:
                        "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "ifg20fe4o2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/ifg20fe4o2?videoFoam=true",
                    },
                    {
                      id: "3",
                      name: "avatar 3",
                      age: "43",
                      location: "Miami, FL",
                      url: require("@/assets/images/avatars/avatar_3.jpg"),
                      bio:
                        "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "59hhhhili9",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/59hhhhili9?videoFoam=true",
                    },
                    {
                      id: "4",
                      name: "avatar 4",
                      age: "26",
                      location: "Austin, Tx",
                      url: require("@/assets/images/avatars/avatar_4.jpg"),
                      bio:
                        "4 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "4ppp68w3j5",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4ppp68w3j5?videoFoam=true",
                    },
                    {
                      id: "5",
                      name: "avatar 5",
                      age: "36",
                      location: "Boston, Ms",
                      url: require("@/assets/images/avatars/avatar_5.jpg"),
                      bio:
                        "5 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "4sbr3gswbd",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4sbr3gswbd?videoFoam=true",
                    },
                    {
                      id: "6",
                      name: "avatar 6",
                      age: "45",
                      location: "Filadelfia, Fd",
                      url: require("@/assets/images/avatars/avatar_6.jpg"),
                      bio:
                        "6 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "c3hzndrprj",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/c3hzndrprj?videoFoam=true",
                    },
                    {
                      id: "7",
                      name: "avatar 7",
                      age: "67",
                      location: "Seattle, Wsh",
                      url: require("@/assets/images/avatars/avatar_7.jpg"),
                      bio:
                        "7 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "iqvb82kz1v",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/iqvb82kz1v?videoFoam=true",
                    },
                    {
                      id: "8",
                      name: "avatar 8",
                      age: "36",
                      location: "San Jose, Ca",
                      url: require("@/assets/images/avatars/avatar_8.jpg"),
                      bio:
                        "8 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "3jci9rkkp2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/3jci9rkkp2?videoFoam=true",
                    },
                    {
                      id: "9",
                      name: "avatar 9",
                      age: "25",
                      location: "New Orleans, NO",
                      url: require("@/assets/images/avatars/avatar_9.jpg"),
                      bio:
                        "9 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "zpyc4c1ou4",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/zpyc4c1ou4?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 2,
              active: true,
              name: "Introduction Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.jpg"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | VIDEO",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "pxrzbkqabx",
                  url:
                    "https://fast.wistia.net/embed/iframe/pxrzbkqabx?videoFoam=true",
                  title: "Avatar video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 3,
              active: true,
              name: "A1C",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select A1C value",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "scale",
                  scale: true,
                  value: 8,
                  mainText:
                    "Select your patient's A1C using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a A1C\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "4",
                  max: "12",
                  step: "0.1",
                  range: [4, 12],
                  ticksLabels: ["4.0", "6.0", "8.0", "10.0", "12.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 4,
              active: true,
              name: "A1C General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "A1C General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 5,
              active: true,
              name: "A1C Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "tmrf458pl1",
                      url:
                        "https://fast.wistia.net/embed/iframe/tmrf458pl1?videoFoam=true",
                      title: "Low A1c video",
                    },
                    {
                      value: "medium",
                      id: "hfgqrdahv0",
                      url:
                        "https://fast.wistia.net/embed/iframe/hfgqrdahv0?videoFoam=true",
                      title: "Medium A1c video",
                    },
                    {
                      value: "high",
                      id: "9byj5tf894",
                      url:
                        "https://fast.wistia.net/embed/iframe/9byj5tf894?videoFoam=true",
                      title: "High A1c video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 6,
              active: true,
              name: "BMI",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select BMI value",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "scale",
                  scale: true,
                  value: 27,
                  mainText:
                    "Select your patient's BMI using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a BMI\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "25",
                  max: "45",
                  step: "0.1",
                  range: [25, 45],
                  ticksLabels: ["25.0", "30.0", "35.0", "40.0", "45.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 7,
              active: true,
              name: "BMI General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "8tf3r4k328",
                  url:
                    "https://fast.wistia.net/embed/iframe/8tf3r4k328?videoFoam=true",
                  title: "BMI General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 8,
              active: true,
              name: "BMI Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC BMI VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "bclku39c3c",
                      url:
                        "https://fast.wistia.net/embed/iframe/bclku39c3c?videoFoam=true",
                      title: "Low BMI video",
                    },
                    {
                      value: "medium",
                      id: "dkl15b5xhv",
                      url:
                        "https://fast.wistia.net/embed/iframe/dkl15b5xhv?videoFoam=true",
                      title: "Medium BMI video",
                    },
                    {
                      value: "high",
                      id: "jxwy82yp28",
                      url:
                        "https://fast.wistia.net/embed/iframe/jxwy82yp28?videoFoam=true",
                      title: "High BMI video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 9,
              active: true,
              name: "Comorbidities",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select Comorbidities",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorComorbidity",
                  avatar: true,
                  avatars: [
                    {
                      id: "10",
                      name: "CVD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CVD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/gipsa9ur4b?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/pm1w5onh1l?videoFoam=true",
                    },
                    {
                      id: "11",
                      name: "CKD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CKD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/i6f04iywt3?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/6wtjuvh37l?videoFoam=true",
                    },
                    {
                      id: "12",
                      name: "Thyroid",
                      url: require("@/assets/images/comorbidities/Comorbidity_Thyroid.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/b982p2ufzb?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/uags2tsaya?videoFoam=true",
                    },
                    {
                      id: "13",
                      name: "NASH",
                      url: require("@/assets/images/comorbidities/Comorbidity_NASH.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/omvnohs9c7?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/8oez5gduff?videoFoam=true",
                    },
                    {
                      id: "14",
                      name: "PCOS",
                      url: require("@/assets/images/comorbidities/Comorbidity_PCOS.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/v7k6f11jjc?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/o1bo0t7ezd?videoFoam=true",
                    },
                    {
                      id: "15",
                      name: "Transplant",
                      url: require("@/assets/images/comorbidities/Comorbidity_Transplant.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/ojlkcun39o?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/qgav4rf396?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 10,
              active: true,
              name: "Comorbidity Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 11,
              active: true,
              name: "Patient Profile",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile and Lab Section",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 12,
              active: true,
              name: "Patient Profile/Lab results Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 13,
              active: true,
              name: "Select Modifiable Behaviours",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable Behaviours",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorMB",
                  avatar: true,
                  avatars: [
                    {
                      id: "16",
                      name: "Nutrition",
                      url: require("@/assets/images/behaviours/BYOCS_Nutrition.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gssl05rrfd?videoFoam=true",
                    },
                    {
                      id: "17",
                      name: "PhysicalActivity",
                      url: require("@/assets/images/behaviours/BYOCS_PhysicalActivity.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/9grvi3stc6?videoFoam=true",
                    },
                    {
                      id: "18",
                      name: "MentalHealth",
                      url: require("@/assets/images/behaviours/BYOCS_MentalHealth.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/t7ycw3t5bi?videoFoam=true",
                    },
                    {
                      id: "19",
                      name: "StressManagement",
                      url: require("@/assets/images/behaviours/BYOCS_StressManagement.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gn1ko61026?videoFoam=true",
                    },
                    {
                      id: "20",
                      name: "Sleep",
                      url: require("@/assets/images/behaviours/BYOCS_Sleep.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/8xb8cavuu1?videoFoam=true",
                    },
                    {
                      id: "21",
                      name: "SmokingEtOH",
                      url: require("@/assets/images/behaviours/BYOCS_SmokingEtOH.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ec2o2l5vyo?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 14,
              active: true,
              name: "Modifiable Behaviour Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable behaviour video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 15,
              active: true,
              name: "Pre SDoH",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile and Lab Section",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "preSDoH",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 16,
              active: true,
              name: "SDoH No",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH No video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 17,
              active: true,
              name: "SDoH",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Social determinants of health",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorSDoH",
                  avatar: true,
                  avatars: [
                    {
                      id: "22",
                      name: "Education",
                      url: require("@/assets/images/sdoh/education.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/55c17ujy8z?videoFoam=true",
                    },
                    {
                      id: "23",
                      name: "Comunity Context",
                      url: require("@/assets/images/sdoh/comunity_context.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/59l7rp7rst?videoFoam=true",
                    },
                    {
                      id: "24",
                      name: "Economic Stability",
                      url: require("@/assets/images/sdoh/economic_stability.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/1nmo6czq33?videoFoam=true",
                    },
                    {
                      id: "25",
                      name: "Health Care",
                      url: require("@/assets/images/sdoh/healthcare.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/zhk3af0fcf?videoFoam=true",
                    },
                    {
                      id: "26",
                      name: "Neighborhood",
                      url: require("@/assets/images/sdoh/neighborhood.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/2lw7tokuoq?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 18,
              active: true,
              name: "SDoH Si",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH Specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 19,
              active: true,
              name: "SDoH General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH General video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "bo1c3cnxat",
                  url:
                    "https://fast.wistia.net/embed/iframe/bo1c3cnxat?videoFoam=true",
                  title: "SDoH General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 20,
              active: true,
              name: "GLP-1 RA",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select GLP-1 RA",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorGLP1",
                  avatar: true,
                  avatars: [
                    {
                      id: "26",
                      name: "SemaglitudeOral",
                      url: require("@/assets/images/GLP1/semaglutideOral_1.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/pc790cxxww?videoFoam=true",
                    },
                    {
                      id: "27",
                      name: "ExcenatideER",
                      url: require("@/assets/images/GLP1/excenatideER_2.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/vgulm729wa?videoFoam=true",
                    },
                    {
                      id: "28",
                      name: "Excenatide",
                      url: require("@/assets/images/GLP1/exenatide_3.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ldwtqnxzis?videoFoam=true",
                    },
                    {
                      id: "29",
                      name: "Liragutide",
                      url: require("@/assets/images/GLP1/liragutide_4.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/m8wuktlfuf?videoFoam=true",
                    },
                    {
                      id: "30",
                      name: "Semaglitude",
                      url: require("@/assets/images/GLP1/semaglutide_5.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/xz4zgvd38t?videoFoam=true",
                    },
                    {
                      id: "31",
                      name: "Dulaglutide",
                      url: require("@/assets/images/GLP1/dulaglutide_6.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/mbhemgpyhp?videoFoam=true",
                    },
                    {
                      id: "32",
                      name: "Lixisenatide",
                      url: require("@/assets/images/GLP1/lixisenatide_7.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/uyjtu6bglt?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 21,
              active: true,
              name: "GLP-1 RA Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 22,
              active: true,
              name: "GLP-1 RA Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 23,
              active: true,
              name: "Side Effects Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Side Efects Video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "Side Effects",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 24,
              active: true,
              name: "Library",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Library",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
          ],
        },
        {
          id: 3,
          name: "CASE 3",
          description: "",
          hasSubSteps: true,
          subSteps: [
            {
              id: 1,
              active: true,
              name: "Avatar",
              hasNavigation: true,
              hasLeftSection: true,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile selection",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selector",
                  avatar: true,
                  avatars: [
                    {
                      id: "1",
                      name: "avatar 1",
                      age: "64",
                      location: "Madison, Wi",
                      url: require("@/assets/images/avatars/avatar_1.jpg"),
                      bio:
                        "1 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "roxlycl8pw",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/roxlycl8pw?videoFoam=true",
                    },
                    {
                      id: "2",
                      name: "avatar 2",
                      age: "54",
                      location: "New Jersey, NY",
                      url: require("@/assets/images/avatars/avatar_2.jpg"),
                      bio:
                        "2 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "ifg20fe4o2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/ifg20fe4o2?videoFoam=true",
                    },
                    {
                      id: "3",
                      name: "avatar 3",
                      age: "43",
                      location: "Miami, FL",
                      url: require("@/assets/images/avatars/avatar_3.jpg"),
                      bio:
                        "3 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "59hhhhili9",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/59hhhhili9?videoFoam=true",
                    },
                    {
                      id: "4",
                      name: "avatar 4",
                      age: "26",
                      location: "Austin, Tx",
                      url: require("@/assets/images/avatars/avatar_4.jpg"),
                      bio:
                        "4 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "4ppp68w3j5",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4ppp68w3j5?videoFoam=true",
                    },
                    {
                      id: "5",
                      name: "avatar 5",
                      age: "36",
                      location: "Boston, Ms",
                      url: require("@/assets/images/avatars/avatar_5.jpg"),
                      bio:
                        "5 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "4sbr3gswbd",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/4sbr3gswbd?videoFoam=true",
                    },
                    {
                      id: "6",
                      name: "avatar 6",
                      age: "45",
                      location: "Filadelfia, Fd",
                      url: require("@/assets/images/avatars/avatar_6.jpg"),
                      bio:
                        "6 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "c3hzndrprj",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/c3hzndrprj?videoFoam=true",
                    },
                    {
                      id: "7",
                      name: "avatar 7",
                      age: "67",
                      location: "Seattle, Wsh",
                      url: require("@/assets/images/avatars/avatar_7.jpg"),
                      bio:
                        "7 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "iqvb82kz1v",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/iqvb82kz1v?videoFoam=true",
                    },
                    {
                      id: "8",
                      name: "avatar 8",
                      age: "36",
                      location: "San Jose, Ca",
                      url: require("@/assets/images/avatars/avatar_8.jpg"),
                      bio:
                        "8 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "3jci9rkkp2",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/3jci9rkkp2?videoFoam=true",
                    },
                    {
                      id: "9",
                      name: "avatar 9",
                      age: "25",
                      location: "New Orleans, NO",
                      url: require("@/assets/images/avatars/avatar_9.jpg"),
                      bio:
                        "9 Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
                      idVideoSDoHNo: "zpyc4c1ou4",
                      urlVideoSDoHNo:
                        "https://fast.wistia.net/embed/iframe/zpyc4c1ou4?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 2,
              active: true,
              name: "Introduction Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.jpg"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | VIDEO",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "pxrzbkqabx",
                  url:
                    "https://fast.wistia.net/embed/iframe/pxrzbkqabx?videoFoam=true",
                  title: "Avatar video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 3,
              active: true,
              name: "A1C",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select A1C value",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "scale",
                  scale: true,
                  value: 8,
                  mainText:
                    "Select your patient's A1C using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a A1C\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "4",
                  max: "12",
                  step: "0.1",
                  range: [4, 12],
                  ticksLabels: ["4.0", "6.0", "8.0", "10.0", "12.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 4,
              active: true,
              name: "A1C General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "A1C General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 5,
              active: true,
              name: "A1C Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC A1C VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "tmrf458pl1",
                      url:
                        "https://fast.wistia.net/embed/iframe/tmrf458pl1?videoFoam=true",
                      title: "Low A1c video",
                    },
                    {
                      value: "medium",
                      id: "hfgqrdahv0",
                      url:
                        "https://fast.wistia.net/embed/iframe/hfgqrdahv0?videoFoam=true",
                      title: "Medium A1c video",
                    },
                    {
                      value: "high",
                      id: "9byj5tf894",
                      url:
                        "https://fast.wistia.net/embed/iframe/9byj5tf894?videoFoam=true",
                      title: "High A1c video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 6,
              active: true,
              name: "BMI",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select BMI value",
                hasSubTitle: false,
                subtitle: "",
                hasContent: true,
                content: {
                  type: "scale",
                  scale: true,
                  value: 27,
                  mainText:
                    "Select your patient's BMI using the sliding\n" +
                    "                                          scale<br>Move\n" +
                    "                                          the slider to the selected value",
                  subText:
                    "For your patient, you have selected a BMI\n" +
                    "                                              of:",
                  rules: [
                    // v => v <= 40 || 'Only 40 in stock',
                  ],
                  min: "25",
                  max: "45",
                  step: "0.1",
                  range: [25, 45],
                  ticksLabels: ["25.0", "30.0", "35.0", "40.0", "45.0"],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 7,
              active: true,
              name: "BMI General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GENERAL VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "8tf3r4k328",
                  url:
                    "https://fast.wistia.net/embed/iframe/8tf3r4k328?videoFoam=true",
                  title: "BMI General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 8,
              active: true,
              name: "BMI Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SPECIFIC BMI VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  videos: [
                    {
                      value: "low",
                      id: "bclku39c3c",
                      url:
                        "https://fast.wistia.net/embed/iframe/bclku39c3c?videoFoam=true",
                      title: "Low BMI video",
                    },
                    {
                      value: "medium",
                      id: "dkl15b5xhv",
                      url:
                        "https://fast.wistia.net/embed/iframe/dkl15b5xhv?videoFoam=true",
                      title: "Medium BMI video",
                    },
                    {
                      value: "high",
                      id: "jxwy82yp28",
                      url:
                        "https://fast.wistia.net/embed/iframe/jxwy82yp28?videoFoam=true",
                      title: "High BMI video",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 9,
              active: true,
              name: "Comorbidities",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select Comorbidities",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorComorbidity",
                  avatar: true,
                  avatars: [
                    {
                      id: "10",
                      name: "CVD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CVD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/gipsa9ur4b?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/pm1w5onh1l?videoFoam=true",
                    },
                    {
                      id: "11",
                      name: "CKD",
                      url: require("@/assets/images/comorbidities/Comorbidity_CKD.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/i6f04iywt3?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/6wtjuvh37l?videoFoam=true",
                    },
                    {
                      id: "12",
                      name: "Thyroid",
                      url: require("@/assets/images/comorbidities/Comorbidity_Thyroid.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/b982p2ufzb?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/uags2tsaya?videoFoam=true",
                    },
                    {
                      id: "13",
                      name: "NASH",
                      url: require("@/assets/images/comorbidities/Comorbidity_NASH.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/omvnohs9c7?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/8oez5gduff?videoFoam=true",
                    },
                    {
                      id: "14",
                      name: "PCOS",
                      url: require("@/assets/images/comorbidities/Comorbidity_PCOS.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/v7k6f11jjc?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/o1bo0t7ezd?videoFoam=true",
                    },
                    {
                      id: "15",
                      name: "Transplant",
                      url: require("@/assets/images/comorbidities/Comorbidity_Transplant.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/ojlkcun39o?videoFoam=true",
                      video2:
                        "https://fast.wistia.net/embed/iframe/qgav4rf396?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 10,
              active: true,
              name: "Comorbidity Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 11,
              active: true,
              name: "Patient Profile",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile and Lab Section",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 12,
              active: true,
              name: "Patient Profile/Lab results Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Comorbidity specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 13,
              active: true,
              name: "Select Modifiable Behaviours",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable Behaviours",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorMB",
                  avatar: true,
                  avatars: [
                    {
                      id: "16",
                      name: "Nutrition",
                      url: require("@/assets/images/behaviours/BYOCS_Nutrition.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gssl05rrfd?videoFoam=true",
                    },
                    {
                      id: "17",
                      name: "PhysicalActivity",
                      url: require("@/assets/images/behaviours/BYOCS_PhysicalActivity.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/9grvi3stc6?videoFoam=true",
                    },
                    {
                      id: "18",
                      name: "MentalHealth",
                      url: require("@/assets/images/behaviours/BYOCS_MentalHealth.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/t7ycw3t5bi?videoFoam=true",
                    },
                    {
                      id: "19",
                      name: "StressManagement",
                      url: require("@/assets/images/behaviours/BYOCS_StressManagement.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/gn1ko61026?videoFoam=true",
                    },
                    {
                      id: "20",
                      name: "Sleep",
                      url: require("@/assets/images/behaviours/BYOCS_Sleep.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/8xb8cavuu1?videoFoam=true",
                    },
                    {
                      id: "21",
                      name: "SmokingEtOH",
                      url: require("@/assets/images/behaviours/BYOCS_SmokingEtOH.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ec2o2l5vyo?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 14,
              active: true,
              name: "Modifiable Behaviour Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Modifiable behaviour video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 15,
              active: true,
              name: "Pre SDoH",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Patient profile and Lab Section",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "preSDoH",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 16,
              active: true,
              name: "SDoH No",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH No video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 17,
              active: true,
              name: "SDoH",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Social determinants of health",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorSDoH",
                  avatar: true,
                  avatars: [
                    {
                      id: "22",
                      name: "Education",
                      url: require("@/assets/images/sdoh/education.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/55c17ujy8z?videoFoam=true",
                    },
                    {
                      id: "23",
                      name: "Comunity Context",
                      url: require("@/assets/images/sdoh/comunity_context.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/59l7rp7rst?videoFoam=true",
                    },
                    {
                      id: "24",
                      name: "Economic Stability",
                      url: require("@/assets/images/sdoh/economic_stability.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/1nmo6czq33?videoFoam=true",
                    },
                    {
                      id: "25",
                      name: "Health Care",
                      url: require("@/assets/images/sdoh/healthcare.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/zhk3af0fcf?videoFoam=true",
                    },
                    {
                      id: "26",
                      name: "Neighborhood",
                      url: require("@/assets/images/sdoh/neighborhood.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/2lw7tokuoq?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 18,
              active: true,
              name: "SDoH Si",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH Specific video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: true,
                  video: [
                    {
                      id: "1",
                      url: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 19,
              active: true,
              name: "SDoH General Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | SDoH General video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "bo1c3cnxat",
                  url:
                    "https://fast.wistia.net/embed/iframe/bo1c3cnxat?videoFoam=true",
                  title: "SDoH General Video",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 20,
              active: true,
              name: "GLP-1 RA",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: require("@/assets/images/logo.png"),
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Action",
                    action: "action",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Select GLP-1 RA",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "selectorGLP1",
                  avatar: true,
                  avatars: [
                    {
                      id: "26",
                      name: "SemaglitudeOral",
                      url: require("@/assets/images/GLP1/semaglutideOral_1.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/pc790cxxww?videoFoam=true",
                    },
                    {
                      id: "27",
                      name: "ExcenatideER",
                      url: require("@/assets/images/GLP1/excenatideER_2.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/vgulm729wa?videoFoam=true",
                    },
                    {
                      id: "28",
                      name: "Excenatide",
                      url: require("@/assets/images/GLP1/exenatide_3.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/ldwtqnxzis?videoFoam=true",
                    },
                    {
                      id: "29",
                      name: "Liragutide",
                      url: require("@/assets/images/GLP1/liragutide_4.jpg"),
                      video:
                        "https://fast.wistia.net/embed/iframe/m8wuktlfuf?videoFoam=true",
                    },
                    {
                      id: "30",
                      name: "Semaglitude",
                      url: require("@/assets/images/GLP1/semaglutide_5.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/xz4zgvd38t?videoFoam=true",
                    },
                    {
                      id: "31",
                      name: "Dulaglutide",
                      url: require("@/assets/images/GLP1/dulaglutide_6.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/mbhemgpyhp?videoFoam=true",
                    },
                    {
                      id: "32",
                      name: "Lixisenatide",
                      url: require("@/assets/images/GLP1/lixisenatide_7.jpg"),
                      video1:
                        "https://fast.wistia.net/embed/iframe/uyjtu6bglt?videoFoam=true",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 21,
              active: true,
              name: "GLP-1 RA Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 22,
              active: true,
              name: "GLP-1 RA Specific Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | GLP-1 RA VIDEO",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "videoGLP1",
                  conditional: true,
                  videos: [
                    {
                      value: "",
                      id: "",
                      url: "",
                      title: "",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 23,
              active: true,
              name: "Side Effects Video",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: false,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Side Efects Video",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "video",
                  conditional: false,
                  id: "gqzfc4hfjy",
                  url:
                    "https://fast.wistia.net/embed/iframe/gqzfc4hfjy?videoFoam=true",
                  title: "Side Effects",
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
            {
              id: 24,
              active: true,
              name: "Library",
              hasNavigation: true,
              hasLeftSection: false,
              leftSection: {
                hasTitle: false,
                title: "Title,",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Use the RANDOMIZER",
                    action: "randomAvatar",
                  },
                ],
              },
              hasRightSection: true,
              rightSection: {
                avatarSection: true,
                hasTitle: true,
                title: "Patient Information",
                hasSubtitle: true,
                subTitle: "",
                hasContent: true,
                content: [
                  {
                    type: "image",
                    url: '"@/assets/images/logo.jpg",',
                  },
                  {
                    type: "text",
                    text:
                      "Let's begin your first case study!<br>Select an avatar, then choose to build your case or use the RANDOMIZER to have the system to pick a patient for you.",
                  },
                ],
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                    action: "nextStep",
                  },
                ],
              },
              mainSection: {
                hasTitle: true,
                title: "CASE 1 | Library",
                hasSubTitle: false,
                subtitle: "SubTitle,",
                hasContent: true,
                content: {
                  type: "text",
                  video: [
                    {
                      id: "1",
                      url: "https://i.pravatar.cc/150?img=1",
                    },
                  ],
                },
                hasActions: true,
                actions: [
                  {
                    label: "Next step",
                  },
                  {
                    label: "Previous step",
                  },
                ],
              },
            },
          ],
        },
        {
          id: 4,
          name: "SUMMARY",
          description: "",
          hasSubSteps: false,
          resources: {},
        },
        {
          id: 5,
          name: "LIBRARY",
          description: "",
          hasSubSteps: false,
          resources: {},
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["getThemeMode"]),
  },
  watch: {
    currentSubStep: function(value) {
      //jump foward or back in GLP-1
      if (value === 11) {
        this.selectorSpecificVideo = this.selectorSpecificVideoTmp;
      } else if (value === 12) {
        this.showComorbidity = false;
      } else if (value === 16) {
        this.selectorSpecificVideo = this.SDoHnoVideo;
      }
      //randomizer or manual controls for A1C
      if (
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
          .name === "A1C"
      ) {
        if (this.randomAvatarSelected === true) {
          //console.log('automatic a1c');
          let a1cLevel = this.steps[this.currentStep - 1].subSteps[0]
            .mainSection.content.avatars[this.avatarSelected - 1].a1CDefaultNum;
          this.trackingAction("Automatic A1C:" + a1cLevel);
          //console.log(a1cLevel);
          this.changeSliderA1C(a1cLevel);
        } else {
          //console.log('manual');
          this.changeSliderA1C(8);
        }
      }
      //randomizer or manual controls for BMI
      if (
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
          .name === "BMI"
      ) {
        if (this.randomAvatarSelected === true) {
          //console.log('automatic bmi');
          let bmiLevel = this.steps[this.currentStep - 1].subSteps[0]
            .mainSection.content.avatars[this.avatarSelected - 1].bmiDefaultNum;
          this.trackingAction("Automatic BMI:" + a1cLevel);
          //console.log(bmiLevel);
          this.changeSliderBMI(bmiLevel);
        } else {
          //console.log('manual');
          this.changeSliderBMI(27);
        }
      }
      //randomizer or manual controls for Comorbidities
      if (
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
          .name === "Comorbidities"
      ) {
        if (this.randomAvatarSelected === true) {
          //console.log('automatic comorbidity');
          let comorbidity = this.steps[this.currentStep - 1].subSteps[0]
            .mainSection.content.avatars[this.avatarSelected - 1]
            .comorbidity_default_num;
          this.trackingAction("Automatic Comorbitity:" + comorbidity);
          //console.log(comorbidity);
          //console.log(this.steps[this.currentStep - 1].subSteps[8].mainSection.content.avatars);
          let number = this.steps[
            this.currentStep - 1
          ].subSteps[8].mainSection.content.avatars.find(
            ({ name }) => name === comorbidity
          ).id;
          let name = this.steps[
            this.currentStep - 1
          ].subSteps[8].mainSection.content.avatars.find(
            ({ name }) => name === comorbidity
          ).name;
          let video1 = this.steps[
            this.currentStep - 1
          ].subSteps[8].mainSection.content.avatars.find(
            ({ name }) => name === comorbidity
          ).video1;
          let video2 = this.steps[
            this.currentStep - 1
          ].subSteps[8].mainSection.content.avatars.find(
            ({ name }) => name === comorbidity
          ).video2;
          this.imageComorbidiy = this.steps[
            this.currentStep - 1
          ].subSteps[8].mainSection.content.avatars.find(
            ({ name }) => name === comorbidity
          ).url;
          this.selectComorbidity(number, name, video1, video2);
        } else {
          //console.log('manual');
          //this.bmiLevel = 27;
        }
      }
      //randomizer or manual controls for Profile
      if (
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
          .name === "Patient Profile"
      ) {
        this.patientProfile = true;
        if (this.randomAvatarSelected === true) {
          console.log("automatic profile");
          this.medicalHistory = this.steps[
            this.currentStep - 1
          ].subSteps[0].mainSection.content.avatars[
            this.avatarSelected - 1
          ].medicalHistory;
          this.treatmentRegimen = this.steps[
            this.currentStep - 1
          ].subSteps[0].mainSection.content.avatars[
            this.avatarSelected - 1
          ].treatmentRegimen;
          this.labResults = this.steps[
            this.currentStep - 1
          ].subSteps[0].mainSection.content.avatars[
            this.avatarSelected - 1
          ].labResults;
        } else {
          console.log("manual profile");
          this.medicalHistory =
            "Medical History for combination Avatar:" +
            this.avatarName +
            ", A1C level:" +
            this.a1cLevel +
            ", BMI level" +
            this.bmiLevel +
            "Comorbidity:" +
            this.comorbiditySelected;
          this.treatmentRegimen =
            "Medical History for combination Avatar:" +
            this.avatarName +
            ", A1C level:" +
            this.a1cLevel +
            ", BMI level" +
            this.bmiLevel +
            "Comorbidity:" +
            this.comorbiditySelected;
          this.labResults =
            "Medical History for combination Avatar:" +
            this.avatarName +
            ", A1C level:" +
            this.a1cLevel +
            ", BMI level" +
            this.bmiLevel +
            "Comorbidity:" +
            this.comorbiditySelected;
          this.selectorSpecificVideo = this.selectorSpecificVideoTmp;
        }
      }
      //randomizer or manual for MB
      if (
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
          .name === "Modifiable Behaviours"
      ) {
        if (this.randomAvatarSelected === true) {
          console.log("automatic MB");
          console.log("automatic comorbidity");
          let mb = this.steps[this.currentStep - 1].subSteps[0].mainSection
            .content.avatars[this.avatarSelected - 1]
            .modifiable_behaviour_default;
          console.log(mb);
          console.log(
            this.steps[this.currentStep - 1].subSteps[12].mainSection.content
              .avatars
          );
          let number = this.steps[
            this.currentStep - 1
          ].subSteps[12].mainSection.content.avatars.find(
            ({ name }) => name === "Nutrition"
          ).id;
          console.log(number);
          let name = this.steps[
            this.currentStep - 1
          ].subSteps[12].mainSection.content.avatars.find(
            ({ name }) => name === "Nutrition"
          ).name;
          let video = this.steps[
            this.currentStep - 1
          ].subSteps[12].mainSection.content.avatars.find(
            ({ name }) => name === "Nutrition"
          ).video;
          this.imageMB = this.steps[
            this.currentStep - 1
          ].subSteps[12].mainSection.content.avatars.find(
            ({ name }) => name === "Nutrition"
          ).url;
          this.selectMB(number, name, video);
        } else {
          console.log("manual MB");
        }
      }
    },
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      ,
      "changeVerticalSidebarMini",
      "signOut",
    ]),
    loadItems() {
      //this.items = []
      //axios.get(`http://byocs.test/api/data`)
      //  .then((response) => {
      //console.log(response.data);
      //  this.items = response.data;
      //console.log(this.items);
      //}).catch((error) => {
      //console.log(error)
      //})
    },
    addColorChips() {
      console.log(
        this.steps[0].subSteps[0].mainSection.content.avatars[0].name
      );
      console.log(this.steps[0].subSteps[0].rightSection.title);
      console.log(
        this.items[0].subSteps[0].mainSection.content.avatars[0].name
      );
      console.log(this.items[0].subSteps[0].rightSection.title);
      if (this.chips.indexOf(this.steps[this.currentStep - 1].name) === -1) {
        this.chips.push(this.steps[this.currentStep - 1].name);
      }
      if (
        this.subChips.indexOf(
          this.steps[this.currentStep - 1].name +
            " " +
            this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
              .name
        ) === -1
      ) {
        this.subChips.push(
          this.steps[this.currentStep - 1].name +
            " " +
            this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1]
              .name
        );
      }
    },
    removeColorChips() {
      this.chips.pop(this.steps[this.currentStep - 1].name);
      this.subChips.pop(
        this.steps[this.currentStep - 1].subSteps[this.currentSubStep - 1].name
      );
    },
    toggleVerticalSidebarDrawer() {
      //this.changeVerticalSidebarDrawer();
      // this.$emit("update:mini-variant");
      //console.log("check diabetes");
    },
    actionsLeftSection(action) {
      if (action === "randomAvatar") {
        this.randomAvatar();
      }
    },
    actionsRightSection(action) {
      if (action === "nextStep") {
        this.nextStep(this.currentStep, this.currentSubStep);
      }
    },
    selectAvatar(n, b) {
      this.trackingAction("Choose Avatar");
      this.currentSelector = n;
      this.randomAvatarSelected = false;
      this.showAvatar = true;
      this.avatarSelected = this.currentSelector;
      this.avatarUrl = require("@/assets/images/avatars/avatar_" +
        this.avatarSelected +
        ".jpg");
      this.avatarRealUrl = require("@/assets/images/avatars/avatar_real_" +
        this.avatarSelected +
        ".jpg");
      this.avatarBio = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].bio;
      this.avatarName = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].name;
      this.avatarAge = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].age;
      this.avatarDescription = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].avatarDescription;
      this.avatarLocation = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].location;
      this.SDoHnoVideo = this.steps[0].subSteps[0].mainSection.content.avatars[
        n - 1
      ].urlVideoSDoHNo;
      this.trackingAction("Selected avatar:" + this.avatarName);
      if (b === true) {
      }
    },
    selectComorbidity(m, name, video1, video2) {
      this.showComorbidity = true;
      this.comorbiditySelected = name;
      this.selectorSpecificVideo = video1;
      this.selectorSpecificVideoTmp = video2;
      this.trackingAction("Manual Comorbidity:" + this.comorbiditySelected);
      //console.log(this.selectorSpecificVideo);
    },
    selectMB(m, name, video) {
      this.showMB = true;
      this.MBSelected = name;
      this.selectorSpecificVideo = video;
      this.trackingAction("Modibifiable behaviour:" + this.MBSelected);
    },
    hasSDoH(val) {
      this.SDoH = val;
      //console.log(this.SDoH)
    },
    selectSDoH(m, name, video) {
      this.showSDoH = true;
      this.SDoHSelected = name;
      this.selectorSpecificVideo = video;
      this.trackingAction("SDoH:" + this.SDoHSelected);
    },
    selectGLP1(m, name, video) {
      this.showGLP1 = true;
      this.GLP1Selected = name;
      this.selectorSpecificVideo = video;
      this.trackingAction("GLP1:" + this.GLP1Selected);
    },
    btnGLP1(val) {
      if (val === true) {
        this.currentSubStep = this.currentSubStep - 1;
      } else {
        this.currentSubStep = this.currentSubStep + 1;
      }
    },
    randomAvatar() {
      this.trackingAction("Random avatar");
      let min = Math.ceil(1);
      let max = Math.floor(9);
      let random = Math.floor(Math.random() * (max - min + 1)) + min;
      this.selectAvatar(random, true);
      this.randomAvatarSelected = true;
    },
    start() {
      this.introduction.active = false;
    },
    nextStep(step, subStep) {
      if (this.steps[step - 1].hasSubSteps) {
        this.currentStep = step;
        if (this.currentSubStep === 15 && this.SDoH === true) {
          this.currentSubStep = subStep + 2;
        } else {
          this.currentSubStep = subStep + 1;
        }
        //console.log(this.steps[step - 1].subSteps.length);
        if (subStep === this.steps[step - 1].subSteps.length) {
          this.currentStep = step + 1;
          this.currentSubStep = 1;
          this.removePreviousSubSteps(step - 1);
        }
      }
      this.trackingAction("Next step");
      //console.log('step:' + this.currentStep);
      //console.log('subStep:' + this.currentSubStep);
      this.addColorChips();
    },
    previousStep(step, subStep) {
      if (this.steps[step - 1].hasSubSteps && step > 0) {
        this.currentStep = step;
        if (this.currentSubStep === 17 && this.SDoH === true) {
          this.currentSubStep = subStep - 2;
        } else {
          this.currentSubStep = subStep - 1;
        }
      }
      this.trackingAction("Previous step");
      //console.log('step:' + this.currentStep);
      //console.log('subStep:' + this.currentSubStep);
      this.removeColorChips();
    },
    removePreviousSubSteps(step) {
      for (let i = 0; i < this.steps[step].subSteps.length; i++) {
        console.log(
          this.steps[step].name + " " + this.steps[step].subSteps[i].name
        );
        if (
          this.subChips.indexOf(
            this.steps[step].name + " " + this.steps[step].subSteps[i].name
          ) != -1
        ) {
          this.subChips.splice(
            this.subChips.indexOf(
              this.steps[step].name + " " + this.steps[step].subSteps[i].name
            ),
            1
          );
        }
      }
      //console.log(this.steps[step].name);
      this.chipsCompleted.push(this.steps[step].name);
    },
    hasNavigation() {
      if (this.steps[this.currentStep - 1].hasSubSteps) {
        return this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep - 1
        ].hasNavigation;
      } else {
        return this.steps[this.currentStep - 1].hasNavigation;
      }
    },
    changeSliderA1C(value) {
      //this.selectorLevel = value
      //console.log(this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[this.currentSubStep + 1].mainSection.content.videos);
      this.a1cLevel = value;
      if (value >= 4 && value <= 6.5) {
        this.selectorLevel = "low";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(({ value }) => value === "low").url;
      } else if (value >= 6.6 && value <= 7.9) {
        this.selectorLevel = "medium";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(
          ({ value }) => value === "medium"
        ).url;
      } else if (value >= 8 && value <= 12) {
        this.selectorLevel = "high";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(({ value }) => value === "high").url;
      } else {
        this.selectorLevel = "";
        this.selectorSpecificVideo = "";
      }
      this.trackingAction("Manual A1C:" + value);
    },
    changeSliderBMI(value) {
      //console.log(this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[this.currentSubStep + 1].mainSection.content.videos);
      this.bmiLevel = value;
      if (value >= 25 && value <= 30) {
        this.selectorLevel = "low";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(({ value }) => value === "low").url;
      } else if (value >= 30 && value <= 35) {
        this.selectorLevel = "medium";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(
          ({ value }) => value === "medium"
        ).url;
      } else if (value >= 35 && value <= 45) {
        this.selectorLevel = "high";
        this.selectorSpecificVideo = this.steps[this.currentStep - 1].subSteps[
          this.currentSubStep + 1
        ].mainSection.content.videos.find(({ value }) => value === "high").url;
      } else {
        this.selectorLevel = "";
        this.selectorSpecificVideo = "";
      }
      this.trackingAction("Manual BMI:" + value);
    },
    saveNotes() {
      //upsert function
      if (this.noteForUpdate) {
        this.notes[this.idNoteForUpdate].text = this.currentNote;
        this.currentNote = "";
      } else {
        let note = new Array();
        note.text = this.currentNote;
        note.icon = "mdi-book";
        this.notes.push(note);
        this.currentNote = "";
      }
      //console.log(this.notes);
    },
    selectedNoteList(i) {
      //console.log(i);
      this.noteForUpdate = true;
      this.idNoteForUpdate = i;
      this.currentNote = this.notes[i].text;
    },
    async trackingAction(action) {
      if (action === "init") {
        this.uuid = this.$route.params.uuid;
        var uuidInput = document.getElementById("uuid");
        uuidInput.value = this.$route.params.uuid;
        this.firstName = this.$route.params.fname;
        this.lastName = this.$route.params.lname;
        this.email = this.$route.params.email;
        this.platform = this.$route.params.platform;
        this.learnerType = this.$route.params.learnertype;
        var axios = require("axios");
        var data = JSON.stringify({
          uuid: this.uuid,
          platform: this.platform,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          learnerType: this.learnerType,
        });

        var config = {
          method: "post",
          url: "http://byocs.test/api/log_user",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(function(response) {
            //this.userId = response.data.data.id;
            //console.log(response.data.data.id);
          })
          .catch(function(error) {
            console.log(error);
          });
        this.trackingAction("enter introduction");
      } else {
        var axios = require("axios");
        var data = JSON.stringify({
          uuid: this.uuid,
          case: 1,
          step: this.currentStep,
          subStep: this.currentSubStep,
          action: action,
        });

        var config = {
          method: "post",
          url: "http://byocs.test/api/log_action",
          headers: {
            "Content-Type": "application/json",
          },
          data: data,
        };

        await axios(config)
          .then(function(response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function(error) {
            console.log(error);
          });
      }
      //this.$route.params
    },
  },
  async mounted() {
    //console.log(this.steps);
    //  try {
    //    let response = await axios.get('https://api.publicapis.org/entries')
    //    console.log(response)
    //  } catch (err) {
    //    console.log(err);
    //  }
    try {
      let response = await axios.get("http://byocs.test/api/data");
      this.items = response.data;
      //console.log(this.items[0].subSteps);
    } catch (err) {
      console.log(err);
    }
    this.loadItems();
    //console.log(this.items);
    this.addColorChips();
    //this.randomAvatar();
    //console.log(this.hasNavigation())
    //this.toggleVerticalSidebarDrawer()
    //console.log(this.$route.params);
    this.trackingAction("init");
    window._wq = window._wq || [];
    let context = this;
    _wq.push({
      id: "_all",
      onReady: function(video) {
        video.bind("play", function() {
          var action = "video played:" + video.name();
          var uuidInput = document.getElementById("uuid");
          var axios = require("axios");
          var data = JSON.stringify({
            uuid: uuidInput.value,
            case: 1,
            step: "1",
            subStep: "1",
            action: action,
          });

          var config = {
            method: "post",
            url: "http://byocs.test/api/log_action",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function(response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function(error) {
              console.log(error);
            });

          console.log("video played", video.name());
        });

        video.bind("end", function() {
          var action = "video ended:" + video.name();
          var uuidInput = document.getElementById("uuid");
          var axios = require("axios");
          var data = JSON.stringify({
            uuid: uuidInput.value,
            case: 1,
            step: "1",
            subStep: "1",
            action: action,
          });

          var config = {
            method: "post",
            url: "http://byocs.test/api/log_action",
            headers: {
              "Content-Type": "application/json",
            },
            data: data,
          };

          axios(config)
            .then(function(response) {
              console.log(JSON.stringify(response.data));
            })
            .catch(function(error) {
              console.log(error);
            });

          console.log("video ended", video.name());
        });
      },
    });
  },
};
</script>
